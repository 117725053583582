import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { GiftDetail } from '@core/models/gift/gift-detail';

@Component({
  selector: 'app-live-stream-gifts',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './live-stream-gifts.component.html',
  styleUrl: './live-stream-gifts.component.scss'
})
export class LiveStreamGiftsComponent {
  @Input() data!: GiftDetail;
}
