import {Component, Input, OnInit} from '@angular/core';
import {NgIf} from '@angular/common';

@Component({
    selector: 'app-top-user-border',
    standalone: true,
    imports: [NgIf],
    templateUrl: './top-user-border.component.html',
    styleUrl: './top-user-border.component.scss',
})
export class TopUserBorderComponent implements OnInit {
    @Input() position!: number;
    @Input() userPhoto!: string | undefined;
    ngOnInit() {
        const color = this.getColor();
        this.pathPrefix = `assets/images/shared/user/user-borders/${color}-border/${color}`;
    }

    getColor() {
        switch (this.position) {
            case 1:
                return 'gold';
            case 2:
                return 'platin';
            case 3:
                return 'bronze';
        }
    }
    pathPrefix = 'assets/images/shared/user/user-borders/';
}
