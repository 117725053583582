import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgClass, NgIf} from '@angular/common';

export type GiftTab = 'popular' | 'vip' | '3d' | 'lucky';

@Component({
    selector: 'app-live-stream-gift-tabs',
    standalone: true,
    imports: [NgClass, NgIf],
    templateUrl: './live-stream-gift-tabs.component.html',
    styleUrl: './live-stream-gift-tabs.component.scss',
})
export class LiveStreamGiftTabsComponent implements AfterViewInit {
    @Input() type!: GiftTab;
    @Input() currentTab!: GiftTab;
    @Output() typeChange = new EventEmitter<GiftTab>();

    isHovered = false;

    @ViewChild('iconContainer') iconContainer!: ElementRef;

    ngAfterViewInit() {
        this.iconContainer.nativeElement.innerHTML = this.getIcon();
    }

    onTypeChange() {
        this.typeChange.emit(this.type);
    }

    onHover(isLeave: boolean) {
        this.isHovered = !isLeave;
    }
    getIcon() {
        switch (this.type) {
            case 'popular':
                return `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M4.13189 5.03988L6.45375 2.33105L8.95698 5.2515L10.5767 3.36178L11.8678 4.86811C12.6975 5.83604 13.1535 7.06882 13.1535 8.34366V8.51542C13.1535 9.88226 12.6105 11.1931 11.644 12.1596C10.6775 13.1261 9.36669 13.6691 7.99985 13.6691V13.6691C5.15356 13.6691 2.84619 11.3617 2.84619 8.51545V8.51545C2.84619 7.24061 3.30223 6.00782 4.13189 5.03988Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
            case 'vip':
                return `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.93343 6.66699V9.33363" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.93335 6.66699H11.2905C11.461 6.66699 11.6245 6.73472 11.7451 6.85528C11.8656 6.97584 11.9333 7.13935 11.9333 7.30985V7.63127C11.9333 7.98631 11.6455 8.27413 11.2905 8.27413H9.93335V6.66699Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="8" cy="8" r="6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.2429 9.33333H5.10004" stroke="#949CA8" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.1001 6.66699L5.1001 9.33366" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.24292 6.66699L5.24292 9.33366" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.93343 6.66699V9.33366" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
            case '3d':
                return `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14 10.6945V5.30646C14 4.8298 13.746 4.3898 13.3333 4.1518L8.66667 1.4578C8.254 1.2198 7.746 1.2198 7.33333 1.4578L2.66667 4.15113C2.254 4.3898 2 4.8298 2 5.30646V10.6951C2 11.1718 2.254 11.6118 2.66667 11.8498L7.33333 14.5438C7.746 14.7818 8.254 14.7818 8.66667 14.5438L13.3333 11.8498C13.746 11.6111 14 11.1711 14 10.6945Z" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.00008 14.72V8" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 7.99965L13.82 4.63965" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.17993 4.63965L7.99993 7.99965" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
            case 'lucky':
                return `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="2" y="5.33398" width="8.66667" height="8.66667" rx="2.28571" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5.3335 5.33333V4C5.3335 2.89543 6.22893 2 7.3335 2H12.0002C13.1047 2 14.0002 2.89543 14.0002 4V8.66667C14.0002 9.77124 13.1047 10.6667 12.0002 10.6667H10.6668" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.58365 7.33399C8.62964 7.33416 8.66678 7.37155 8.66667 7.41753C8.66655 7.46351 8.62921 7.50071 8.58323 7.50065C8.53725 7.50059 8.5 7.4633 8.5 7.41732C8.49988 7.39513 8.50866 7.37381 8.52439 7.35815C8.54011 7.34248 8.56146 7.33378 8.58365 7.33399" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.33365 9.58399C6.37964 9.58416 6.41678 9.62155 6.41667 9.66753C6.41655 9.71351 6.37921 9.75071 6.33323 9.75065C6.28725 9.75059 6.25 9.7133 6.25 9.66732C6.24988 9.64513 6.25867 9.62381 6.27439 9.60815C6.29011 9.59249 6.31146 9.58378 6.33365 9.58399" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.08367 11.834C4.12965 11.8342 4.16679 11.8716 4.16667 11.9175C4.16654 11.9635 4.1292 12.0007 4.08322 12.0007C4.03724 12.0006 4 11.9633 4 11.9173C3.99988 11.8951 4.00867 11.8738 4.02439 11.8581C4.04011 11.8425 4.06146 11.8338 4.08365 11.834" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.5 11.917C8.50018 11.871 8.53757 11.8339 8.58355 11.834C8.62953 11.8341 8.66673 11.8714 8.66667 11.9174C8.66661 11.9634 8.62932 12.0006 8.58334 12.0007C8.56114 12.0008 8.53983 11.992 8.52416 11.9763C8.5085 11.9605 8.4998 11.9392 8.5 11.917" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.08301 7.33399C4.03703 7.33416 3.99988 7.37155 4 7.41753C4.00012 7.46351 4.03746 7.50071 4.08344 7.50065C4.12942 7.50059 4.16667 7.4633 4.16667 7.41732C4.16678 7.39513 4.158 7.37381 4.14228 7.35815C4.12655 7.34249 4.10521 7.33378 4.08301 7.33399" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.9165 4.16666C11.8705 4.16648 11.8334 4.12909 11.8335 4.08311C11.8336 4.03713 11.871 3.99994 11.9169 4C11.9629 4.00006 12.0002 4.03735 12.0002 4.08333C12.0003 4.10552 11.9915 4.12684 11.9758 4.1425C11.96 4.15816 11.9387 4.16687 11.9165 4.16666" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
        }
    }
}
