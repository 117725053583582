<div class="marquee">
    <div (animationend)="onAnimationEnd()" class="flex flex-row items-center h-full marquee-text">
        <p>{{ data.senderName }} {{ data.receiverName }} yayınına {{ data.giftName }}</p>
        <div class="flex flex-row items-center">
            (
            <img src="assets/images/shared/crystals.svg" alt="coin" class="size-3" />
            <p>{{ data.giftPrice }}) gönderdi</p>
        </div>
    </div>
</div>
