<div class="w-ful h-full max-h-full bg-black bg-opacity-10 backdrop-blur-md">
    <div class="w-full h-full max-h-full pt-[62px] px-4 lg:px-8 lg:pt-[88px] lg:pb-[10px] flex flex-col items-center">
        <!---- HEADER ---->
        <h3 class="text-lg font-semibold text-center text-white">The stream ended.</h3>

        <!--- DIVIDER ---->
        <div class="w-full border border-[#3F424A] my-8"></div>

        <!---- BODY ---->
        <div class="flex flex-col items-center w-full">
            <img src="{{ room.photo }}" class="size-20 rounded-full" alt="Streamer profile picture" />
            <h3 class="text-lg font-semibold text-center text-white mt-4">{{ room.fullName }}</h3>
            <app-stream-follow-button
                *ngIf="!room.isFollowing"
                [userId]="room.appUserId"
                [userDetail]="{fullName: room.fullName, photo: room.photo}"
                class="mt-8"
            />
        </div>

        <div *ngIf="rooms && rooms.length > 0" class="w-full border border-[#3F424A] my-8"></div>

        <h3 *ngIf="rooms && rooms.length > 0" class="text-lg font-semibold text-center text-white">Suggested Streams</h3>
        <!--- STREAMERS ---->
        <div *ngIf="rooms && rooms.length > 0" class="flex flex-col w-full max-h-full items-center overflow-y-scroll mt-8">
            <div class="grid gap-2 w-full grid-cols-2 sm:grid-cols-4 max-h-full lg:grid-cols-3">
                <app-streamer-card
                    *ngFor="let _room of rooms"
                    [data]="_room"
                    (click)="goDetail(_room.roomId, _room.photo)"
                ></app-streamer-card>
            </div>
        </div>
    </div>
</div>
