import { Component } from '@angular/core';

@Component({
  selector: 'app-premium-stream-badge',
  standalone: true,
  imports: [],
  templateUrl: './premium-stream-badge.component.html',
  styleUrl: './premium-stream-badge.component.scss'
})
export class PremiumStreamBadgeComponent {

}
