<!--- USER INFO -->
<div
    class="flex flex-row justify-between my-2 px-4 py-1 w-full hover:bg-gray-500 transition-colors duration-300 ease-in-out select-none snap-end"
>
    <div class="flex flex-row justify-center items-center gap-2 w-full max-w-full overflow-hidden">
        <div *ngIf="index <= 2 && user.spending !== 0" class="relative w-[18px] min-w-[18px] h-8 min-h-8">
            <img
                src="assets/images/shared/user/viewer-list/top-users/{{ order }}.png"
                alt="First"
                class="w-full h-full"
            />
            <p
                class="absolute top-[60%] left-1/2 transform -translate-x-1/2 -translate-y-[60%] text-[10px] text-white font-semibold"
            >
                {{ index + 1 }}
            </p>
        </div>

        <div
            *ngIf="index > 2 || (user.spending === 0 && index >= 0)"
            class="w-[18px] h-[18px] min-w-[18px] min-h-[18px] px-[3.5px] py-2 bg-[#7E8594] bg-opacity-60 flex items-center justify-center rounded-full"
        >
            <p class="text-[10px] text-white font-semibold">{{ index + 1 }}</p>
        </div>

        <app-user-level-profile-picture [photo]="user.photo" [level]="user.level" class="w-full max-w-8 h-full" />

        <div class="flex flex-col justify-center w-full max-w-full h-full overflow-hidden">
            <p class="text-sm font-semibold text-white truncate">{{ user.fullName }}</p>

            <div class="flex flex-row items-center w-full">
                <app-user-level-badge class="mr-2" *ngIf="user.isPremium" [level]="user.level" />
                <img src="assets/images/shared/crystals.svg" alt="Heart" />
                <p class="text-xs text-white">{{ user.spending }}</p>
            </div>
        </div>
    </div>
    <button *ngIf="showFollowBtn && !user.isFollowing" class="flex justify-center items-center" (click)="followUser()">
        <img src="assets/images/shared/icons/plus-white.svg" alt="Follow" class="w-8 h-8" />
    </button>
</div>
