<app-moved-to-premium
    *ngIf="showPremiumPage && room !== undefined && premiumGift !== undefined"
    [room]="room"
    [gift]="premiumGift"
/>

<div *ngIf="!showPremiumPage" class="h-full w-full max-w-full bg-gray-900">
    <div class="absolute w-full h-full top-0 left-0 bottom-0 right-0 z-0">
        <img src="{{ roomPhoto }}" alt="Streamers" class="h-full w-full object-cover" />
    </div>
    <div class="absolute w-full h-full top-0 left-0 bottom-0 right-0 bg-opacity-40 bg-black"></div>
    <div *ngIf="!room" class="w-full h-full top-0 left-0 right-0 bottom-0 absolute z-10 backdrop-blur-2xl">
        <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full">
            <!-- border--->
            <div
                class="relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full bg-white w-[128px] h-[128px] p-1 flex items-center justify-center glow-effect"
            >
                <img src="{{ roomPhoto }}" alt="Back" class="w-full h-full rounded-full object-cover" />
            </div>
        </div>
    </div>
    <!--- WEB VERSION --->
    <div
        *ngIf="!windowSizeSmall && room"
        @fadeInOut
        class="flex h-full flex-row justify-between m-0 z-30 backdrop-blur-2xl"
    >
        <!--- Left Section --->
        <div class="flex h-full w-full max-w-[266px] justify-between flex-col ml-4 py-6 z-20 relative">
            <!--- Top Section --->
            <div class="flex flex-col">
                <div class="flex flex-row items-center">
                    <div
                        (click)="routeToHome()"
                        class="rounded-full w-12 h-12 bg-[#3F424A] lg:w-8 lg:h-8 flex items-center justify-center cursor-pointer hover:bg-neutral-500 transition-colors duration-300 ease-in-out select-none"
                    >
                        <img src="assets/images/shared/icons/chevron-left.svg" alt="Back" class="w-4 h-4" />
                    </div>
                    <!-- Viewer Counter -->
                    <div
                        #viewerButton
                        (click)="openViewerList()"
                        class="flex flex-row items-center justify-between gap-1 w-auto max-w-44 bg-[#3F424A] cursor-pointer group rounded-full h-9 hover:bg-neutral-500 transition-colors duration-300 ease-in-out ml-3 select-none"
                    >
                        <!--- VIEWER COUNT ---->
                        <div class="flex flex-row items-center justify-center pl-2 pr-4">
                            <img src="assets/images/shared/icons/watcher-icon.svg" alt="Viewer Count" class="w-5 h-5" />
                            <p class="text-xs text-white pl-1">{{ participantCount }}</p>
                        </div>
                    </div>
                    <!--- BEST SUPPORTERS -->
                    <div class="flex flex-row gap-2 w-auto h-7 items-center justify-center p-2 select-none">
                        <app-top-user-border
                            [userPhoto]="bestSupporters.length > 0 ? bestSupporters[0].photo : undefined"
                            [position]="1"
                        />
                        <app-top-user-border
                            [userPhoto]="bestSupporters.length > 1 ? bestSupporters[1].photo : undefined"
                            [position]="2"
                        />
                        <app-top-user-border
                            [userPhoto]="bestSupporters.length > 2 ? bestSupporters[2].photo : undefined"
                            [position]="3"
                        />
                    </div>
                </div>
                <!--- Party Feature(Goal, Sticker) Section ---->
                <div class="flex flex-col justify-center mt-[18px]">
                    <!--- STICKER --->
                    <app-party-feature-sticker
                        *ngIf="stickerData && !isStickerFinished"
                        [featureData]="stickerData"
                        (click)="sendStickerGift(stickerData)"
                    ></app-party-feature-sticker>
                    <!--- GOAL --->
                    <app-party-feature-goal
                        *ngIf="goalData && !isGoalFinished"
                        [featureData]="goalData"
                    ></app-party-feature-goal>
                    <app-party-feature-wheel
                        *ngIf="partyWheelData && !isPartyWheelFinished"
                        [wheelData]="partyWheelData"
                        (spinFinish)="handleWheelResults($event)"
                    />
                </div>
            </div>

            <!--- CHAT SECTION ---->
            <div
                *ngIf="!isViewerListOpen"
                #scrollableContainer
                class="flex flex-col-reverse h-[calc(100%-72px)] w-full max-w-[266px] overflow-scroll-gradient my-2 overflow-y-auto"
            >
                <div class="flex flex-col justify-end max-h-full w-full">
                    <app-live-stream-chat
                        #messages
                        *ngFor="let msg of chatMessages"
                        [message]="msg"
                        [partyUsers]="partyUsers"
                        [room]="room"
                        [gifts]="gifts"
                        [socketService]="socketService"
                    ></app-live-stream-chat>
                </div>
            </div>

            <!--- Viewers --->
            <div
                *ngIf="isViewerListOpen"
                @fadeInOut
                #viewerList
                class="py-4 w-full h-[calc(100%-72px)] my-4 bg-[#3F424A] rounded-lg overflow-y-auto"
            >
                <div class="flex flex-col justify-between max-h-full w-full">
                    <div *ngIf="isViewerListLoading" class="w-full">
                        <div
                            *ngFor="let num of Array.from({length: 25})"
                            class="my-2 rounded-md h-[32px] px-4 w-full flex flex-row justify-between animate-pulse gap-4 select-none"
                        >
                            <div class="animated-bg rounded-full min-w-8 h-8"></div>
                            <div class="w-full animated-bg select-none h-full rounded-md"></div>
                        </div>
                    </div>

                    <div *ngIf="!isViewerListLoading" class="w-full">
                        <app-live-stream-users
                            *ngFor="let user of partyUsers; index as i"
                            [index]="i"
                            [user]="user"
                        ></app-live-stream-users>
                    </div>
                </div>
            </div>

            <!--- CHAT INPUT --->
            <div class="max-w-[266px] relative min-h-8 h-auto max-h-[100px]">
                <textarea
                    [disabled]="streamFinishStatus"
                    (focusin)="onChatInputFocus()"
                    (focusout)="onChatInputFocusOut()"
                    placeholder="Birşey yaz..."
                    class="max-w-full w-full bg-[#3F424A] rounded-[16px] pl-4 pr-16 bg-opacity-40 font-medium text-white text-xs focus:outline-none break-words"
                    [(ngModel)]="partyMessageContent"
                    (keydown.enter)="sendMessage($event)"
                    (input)="adjustTextareaHeight($event)"
                    id="text-input"
                    style="resize: none; overflow: hidden"
                    ngModel
                    rows="1"
                    maxlength="100"
                    #chatInput
                >
                </textarea>
                <div class="absolute flex flex-row items-center justify-between right-1 top-1.5 z-10 gap-2">
                    <div>
                        <emoji-mart
                            (emojiClick)="addEmoji($event)"
                            [set]="'apple'"
                            [@collapse]="!isEmojiPickerOpen"
                            class="absolute bottom-8 origin-bottom-right right-1/2 transform translate-x-1/2"
                        />
                        <img
                            (click)="emojiButtonClicked()"
                            src="assets/images/shared/icons/emoji-icon.svg"
                            alt="Emoji"
                            class="size-6 cursor-pointer"
                        />
                    </div>
                    <div
                        *ngIf="isSendButtonOpen"
                        class="p-1 text-white flex items-center justify-center bg-secondary-500 rounded-full size-6 cursor-pointer"
                        (click)="sendMessage($event)"
                    >
                        <ng-icon name="ionPlay"></ng-icon>
                    </div>
                </div>
            </div>
        </div>

        <!--- Middle Streamer Section --->
        <div class="relative h-full video-container-all" #videoContainerFrame>
            <!--<ng-lottie
                class="absolute top-0 left-0 z-30 h-full w-full"
                [options]="animationOptions"
                (animationCreated)="animationCreated($event)"
                (complete)="animationComplete($event)"
            ></ng-lottie>-->
            <app-stream-end
                *ngIf="streamFinishStatus"
                [room]="room"
                [channelName]="channelName"
                class="absolute top-0 right-0 bottom-0 left-0 z-50"
            />
            <app-host-disconnect *ngIf="streamDisconnectStatus" class="absolute top-0 right-0 bottom-0 left-0 z-50" />
            <app-race
                [raceDetail]="this.raceData"
                (raceFinishedEvent)="handleRaceFinish()"
                [participantList]="partyUsers"
                [sizeChanged]="raceSizeChanged"
                *ngIf="!isRaceFinished"
                class="absolute top-32 right-0 bottom-0 left-0 z-50 w-full"
                style="padding-left: 0.3em; padding-right: 0.3em"
            />
            <!--- MUTE AND NO VIDEO OVERLAY --->

            <!--- STREAMER INFO ---->

            <app-agora-video-container
                #mainAgoraVideoController
                [hidden]="!this.streamResolutionStatus && this.userVideoTrack"
                *ngIf="currentRoomData && showStream"
                [token]="currentRoomData.token"
                [room]="'room-party-' + currentRoomData.roomId"
                [uid]="currentRoomData.watcherId"
                [roomId]="currentRoomData.roomId"
                [stream]="stream"
                [hostId]="room.id"
                (videoPublished)="handleVideoResolution()"
            >
                <!--- MUTE AND NO VIDEO OVERLAY --->

                <app-marquee
                    *ngIf="showMarquee"
                    class="absolute top-20 left-0 z-50 w-full pl-[0.3em] pr-[0.3em]"
                    [data]="marqueeData"
                    (marqueeFinish)="onMarqueeEnd()"
                />

                <!--- STREAMER INFO ---->
                <!--                <div *ngIf="room" class="absolute top-6 left-6 z-50">-->
                <!--                    <div-->
                <!--                        class="flex flex-row grow w-full min-w-40 h-10 p-1 justify-between gap-2 items-center bg-[#3F424A] bg-opacity-40 rounded-full select-none"-->
                <!--                    >-->
                <!--                        <div class="flex flex-row gap-2 items-center justify-center">-->
                <!--                            <img src="{{ room.photo }}" alt="Profile Photo" class="rounded-full size-8 object-cover" />-->
                <!--                            <div class="flex flex-col">-->
                <!--                                <p class="text-sm text-white font-semibold">{{ room.fullName }}</p>-->
                <!--                                <div class="flex flex-row items-center gap-1/2">-->
                <!--                                    <img src="assets/images/shared/crystals.svg" alt="Crystals" class="size-4" />-->
                <!--                                    <p class="text-[10px] text-white">{{ convertCoin(room.earnedCoin) }}</p>-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                        <button *ngIf="!room.isFollowing" (click)="followStreamer(room.id)">-->
                <!--                            <app-follow-button></app-follow-button>-->
                <!--                        </button>-->
                <!--                    </div>-->
                <!--                </div>-->
                <app-premium-stream-badge *ngIf="!room.isFreemium" class="absolute top-6 right-6 z-50" />
            </app-agora-video-container>

            <!--<div class="h-full" id="video-container-{{ roomId }}">
                Streamer Info --

            </div>-->
        </div>

        <!--- Right Section --->
        <div
            class="h-full max-h-full min-w-[266px] max-w-full flex flex-col items-end justify-between gap-2 py-8 px-4 z-20"
        >
            <!--- MUTE BUTTON --->
            <div
                *ngIf="!streamDisconnectStatus && !streamFinishStatus"
                (click)="toogleMute()"
                class="min-h-8 min-w-8 flex justify-center items-center bg-[#3F424A] rounded-full cursor-pointer"
            >
                <button>
                    <img
                        src="assets/images/shared/icons/{{ remoteUser?.isMuted ? 'volume-off' : 'volume' }}.svg"
                        alt="Mute"
                        class="size-4"
                    />
                </button>
            </div>
            <!--- GIFT SECTION --->
            <div
                *ngIf="isGiftListOpen && !isAgent"
                class="flex flex-col h-[calc(100%-124px)] max-w-[266px] w-[266px] bg-[#3F424A] p-4 rounded-[16px]"
            >
                <!--- USER INFO -->
                <div class="w-full flex flex-row items-center justify-between pb-4">
                    <div class="flex flex-row items-center">
                        <img src="assets/images/shared/icons/crystals.svg" alt="Heart" class="w-5 h-5" />
                        <p class="text-xs text-white pl-1">{{ currentUser.balance }}</p>
                    </div>
                    <div>
                        <button
                            (click)="openPurchaseModal()"
                            type="submit"
                            class="bg-gradient-to-l from-[#26A7F0] to-[#BD00FF] rounded-[5.25px] w-auto h-[21.44px] px-4 text-xs text-white text-center"
                        >
                            <p>+ {{ constantService.i18n_constants.Buy_Crystal | transloco }}</p>
                        </button>
                    </div>
                </div>
                <div class="border h-[0.5px] border-[#7E8594] w-full mb-4"></div>

                <div class="flex flex-col">
                    <div *ngIf="remoteUsers.length > 1">
                        <p class="font-bold text-[#D5DAE7] text-[12px] mb-2">Hediye gönderilecek kişi</p>
                        <div class="flex flex-row gap-4 items-center max-w-full overflow-x-scroll">
                            <div
                                *ngFor="let streamer of remoteUsers"
                                (click)="onGiftRoomIdChange(streamer.room?.roomId || 0)"
                                class="flex flex-col gap-1 items-center min-w-[48px] min-h-[68px] justify-center cursor-pointer"
                            >
                                <img
                                    src="{{ streamer.room?.photo }}"
                                    alt="Profile Photo"
                                    class="rounded-full size-10 object-cover"
                                    [ngClass]="
                                        streamer.room?.roomId === giftSelectedRoomId ? 'ring-2 ring-[#26A7F0]' : ''
                                    "
                                />
                                <p class="font-normal text-[10px] text-white text-center max-w-[56px] truncate">
                                    {{ streamer.room?.fullName }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div
                        #giftTabs
                        class="flex flex-row px-2 gap-2 py-[6px] min-h-10 items-center overflow-x-scroll no-scrollbar max-w-full w-full rounded-lg bg-[#51555F]"
                    >
                        <app-live-stream-gift-tabs
                            [currentTab]="giftSelectedTab"
                            [type]="'popular'"
                            (typeChange)="onGiftTabChange($event)"
                        ></app-live-stream-gift-tabs>
                        <app-live-stream-gift-tabs
                            [currentTab]="giftSelectedTab"
                            [type]="'vip'"
                            (typeChange)="onGiftTabChange($event)"
                        ></app-live-stream-gift-tabs>
                        <app-live-stream-gift-tabs
                            [currentTab]="giftSelectedTab"
                            [type]="'3d'"
                            (typeChange)="onGiftTabChange($event)"
                        ></app-live-stream-gift-tabs>
                        <app-live-stream-gift-tabs
                            [currentTab]="giftSelectedTab"
                            [type]="'lucky'"
                            (typeChange)="onGiftTabChange($event)"
                        ></app-live-stream-gift-tabs>
                    </div>
                </div>
                <!--- GIFTS ---->
                <div class="flex flex-col overflow-y-scroll max-h-full w-full mt-4">
                    <!--- GIFT ---->
                    <div class="grid grid-cols-4">
                        <app-live-stream-gifts
                            *ngFor="let gift of currentGifts"
                            [data]="gift"
                            (click)="sendGift(gift)"
                        ></app-live-stream-gifts>
                    </div>
                </div>
            </div>
            <div
                *ngIf="!isGiftListOpen"
                class="flex flex-col h-[calc(100%-124px)] max-h-full overflow-y-scroll snap-end snap-y"
            >
                <app-live-stream-gifts *ngFor="let gift of gifts" [data]="gift" (click)="sendGift(gift)" />
            </div>
            <!--- GIFT BUTTON --->
            <div
                *ngIf="!isAgent"
                (click)="openGiftList()"
                class="rounded-full min-h-10 min-w-10 flex items-center justify-center bg-white cursor-pointer"
            >
                <img src="assets/images/shared/icons/gift-icon.svg" alt="Gift" class="size-5" />
            </div>
        </div>
    </div>

    <!--- MOBILE VERSION --->
    <div *ngIf="windowSizeSmall && room" @fadeInOut class="h-full w-full backdrop-blur-2xl">
        <app-stream-end
            *ngIf="streamFinishStatus"
            [channelName]="channelName"
            [room]="room"
            class="absolute top-0 right-0 bottom-0 left-0 z-50"
        />

        <div *ngIf="streamDisconnectStatus" class="bg-black min-h-full w-full h-full fixed z-20"></div>
        <!-- STREAM VIDEO -->
        <div class="h-full w-full fixed z-10">
            <!--<div class="h-full w-full object-cover" id="video-container-{{roomId}}">
        </div>-->
            <!--- MUTE AND NO VIDEO OVERLAY --->
            <app-marquee
                *ngIf="showMarquee"
                class="absolute top-16 left-0 z-50 w-full"
                [data]="marqueeData"
                (marqueeFinish)="onMarqueeEnd()"
            />

            <app-race
                [raceDetail]="this.raceData"
                (raceFinishedEvent)="handleRaceFinish()"
                [participantList]="partyUsers"
                [sizeChanged]="raceSizeChanged"
                *ngIf="!isRaceFinished"
                class="absolute top-32 right-0 bottom-0 left-0 z-50 w-full"
            />

            <!--- STREAM VIDEO ---->
            <div class="relative h-full w-full video-container-mobile" #videoContainerMobileFrame>
                <div class="containerGiftAnimation h-full w-full left-0 top-0 absolute z-50"></div>
                <app-agora-video-container
                    *ngIf="currentRoomData && showStream"
                    [hidden]="!this.streamResolutionStatus && this.userVideoTrack"
                    [token]="currentRoomData.token"
                    [room]="'room-party-' + currentRoomData.roomId"
                    [uid]="currentRoomData.watcherId"
                    [roomId]="currentRoomData.roomId"
                    [stream]="stream"
                    [hostId]="room.id"
                    [isMobile]="true"
                    (videoPublished)="handleVideoResolution()"
                >
                </app-agora-video-container>
                <!--<div class="h-full" id="video-container-mobile-{{ roomId }}"></div>-->
            </div>
            <!--<img *ngIf="room" src="{{ room.photo }}" alt="Streamer" class="h-full w-full object-cover" />-->
        </div>

        <!--- LAYOUT -->
        <div class="w-full h-full max-h-full p-4 md:p-8 fixed flex flex-col z-30 justify-between">
            <!-- VIEWER AND BACK BUTTON & MUTE -->
            <div class="flex flex-row justify-between">
                <!--- Viewer And Back Button --->
                <div class="flex flex-row gap-3">
                    <div
                        (click)="routeToHome()"
                        class="rounded-full bg-[#3F424A] size-12 bg-opacity-40 flex items-center justify-center select-none"
                    >
                        <img src="assets/images/shared/icons/chevron-left.svg" alt="Back" class="size-6" />
                    </div>

                    <div
                        (click)="openViewerList()"
                        class="px-[10px] py-[9px] bg-[#3F424A] bg-opacity-40 rounded-[150px] h-12 w-auto flex flex-row items-center justify-center"
                    >
                        <img src="assets/images/shared/icons/watcher-icon.svg" alt="Viewer Count" class="size-5" />
                        <p class="text-[18px] text-white">{{ participantCount }}</p>
                    </div>
                </div>
                <!-- Mute -->
                <div class="flex flex-col items-center justify-center">
                    <div
                        *ngIf="!streamDisconnectStatus && !streamFinishStatus"
                        (click)="toogleMute()"
                        class="size-12 flex justify-center items-center bg-[#3F424A] rounded-full"
                    >
                        <button>
                            <img
                                src="assets/images/shared/icons/{{ remoteUser?.isMuted ? 'volume-off' : 'volume' }}.svg"
                                alt="Mute"
                                class="size-6"
                            />
                        </button>
                    </div>

                    <app-premium-stream-badge *ngIf="!room.isFreemium" />
                </div>
            </div>
            <!--- Best Supporters -->
            <div class="flex flex-row gap-2 w-auto h-7 p-2 my-[10px] select-none">
                <app-top-user-border
                    [userPhoto]="bestSupporters.length > 0 ? bestSupporters[0].photo : undefined"
                    [position]="1"
                />
                <app-top-user-border
                    [userPhoto]="bestSupporters.length > 1 ? bestSupporters[1].photo : undefined"
                    [position]="2"
                />
                <app-top-user-border
                    [userPhoto]="bestSupporters.length > 2 ? bestSupporters[2].photo : undefined"
                    [position]="3"
                />
            </div>
            <div class="flex flex-col max-w-[180px] xs:max-w-[266px]">
                <!--- Party Feature(Goal, Sticker) Section ---->
                <app-party-feature-sticker
                    *ngIf="stickerData && !isStickerFinished"
                    [featureData]="stickerData"
                    (click)="sendStickerGift(stickerData)"
                ></app-party-feature-sticker>
                <app-party-feature-goal
                    *ngIf="goalData && !isGoalFinished"
                    [featureData]="goalData"
                ></app-party-feature-goal>
                <app-party-feature-wheel
                    *ngIf="partyWheelData && !isPartyWheelFinished"
                    [wheelData]="partyWheelData"
                    (spinFinish)="handleWheelResults($event)"
                />
            </div>

            <app-host-disconnect *ngIf="streamDisconnectStatus" />

            <!--- CHAT SECTION ---->
            <div
                #scrollableContainer
                class="flex flex-col-reverse h-[calc(100%-268px)] w-full overflow-scroll-gradient mt-[27px] mb-[20px] overflow-y-auto"
            >
                <div class="flex flex-col justify-end max-h-full w-[222px]">
                    <app-live-stream-chat
                        #messages
                        *ngFor="let msg of chatMessages"
                        [message]="msg"
                        [partyUsers]="partyUsers"
                        [room]="room"
                        [gifts]="gifts"
                        [socketService]="socketService"
                    ></app-live-stream-chat>
                </div>
            </div>
            <!--- GIFTS --->
            <div
                *ngIf="!isAgent"
                class="flex flex-row gap-4 h-auto min-h-16 items-center w-full overflow-x-auto overflow-y-hidden snap-x lg:snap-y"
            >
                <app-live-stream-gifts
                    *ngFor="let gift of gifts"
                    [data]="gift"
                    (click)="sendGift(gift)"
                ></app-live-stream-gifts>
            </div>

            <!--- INFO & BUTTONS --->
            <div
                *ngIf="!isChatOpen"
                @fadeInOut
                class="flex flex-row gap-6 justify-between items-center w-full h-14 my-4"
            >
                <!--- CHAT BUBBLE -->
                <div
                    (click)="openChat()"
                    class="flex items-center justify-center rounded-full w-14 h-14 bg-[#3F424A] cursor-pointer"
                >
                    <img src="assets/images/shared/icons/chat-bubble.svg" alt="Chat" class="w-6 h-6" />
                </div>

                <!--- USER INFO --->
                <div
                    *ngIf="room"
                    class="grow flex flex-row justify-between items-center px-1 rounded-full py-1.5 bg-[#3F424A] bg-opacity-40 min-w-[168px] w-auto max-w-[543px]"
                >
                    <div class="flex flex-row justify-between items-center gap-1 shrink">
                        <img [src]="room.photo" alt="Profile Photo" class="size-[44px] rounded-full object-cover" />
                        <div class="flex flex-col justify-center max-w-full truncate shrink">
                            <p class="text-sm text-white font-semibold max-w-full truncate">{{ room.fullName }}</p>
                            <div class="flex flex-row items-center gap-1/2">
                                <img src="assets/images/shared/crystals.svg" alt="Crystals" class="size-4" />
                                <p class="text-[10px] text-white">{{ convertCoin(room.earnedCoin) }}</p>
                            </div>
                        </div>
                    </div>
                    <button *ngIf="!room.isFollowing" (click)="followStreamer(room.id)">
                        <img src="assets/images/shared/icons/plus-white.svg" class="min-h-11 min-w-11" alt="Follow" />
                    </button>
                </div>
                <div
                    *ngIf="!isAgent"
                    (click)="openGiftList()"
                    class="flex items-center justify-center rounded-full w-14 h-14 bg-white"
                >
                    <img src="assets/images/shared/icons/gift-icon.svg" alt="Gift" class="w-6 h-6" />
                </div>
            </div>
            <!--- CHAT INPUT --->
            <div
                *ngIf="isChatOpen"
                (focusin)="onChatInputFocus()"
                (focusout)="onChatInputFocusOut()"
                @fadeInOut
                autofocus
                id="chat-input"
                class="w-full h-12 my-4 relative"
            >
                <input
                    type="text"
                    placeholder="Birşey yaz..."
                    [maxLength]="100"
                    [(ngModel)]="partyMessageContent"
                    (keydown.enter)="sendMessage($event)"
                    (input)="handleMobileChatInput()"
                    class="w-full h-full bg-[#3F424A] rounded-[64px] pl-4 pr-14 bg-opacity-40 font-medium text-white dark:text-white text-xs focus:outline-none"
                />
                <div class="absolute flex flex-row items-center justify-between right-2 top-2 z-10 gap-2">
                    <div>
                        <emoji-mart
                            (emojiClick)="addEmoji($event)"
                            [set]="'apple'"
                            [@collapse]="!isEmojiPickerOpen"
                            class="absolute bottom-8 origin-bottom-right left-1/2 transform -translate-x-full"
                        />
                        <img
                            (click)="emojiButtonClicked()"
                            src="assets/images/shared/icons/emoji-icon.svg"
                            alt="Emoji"
                            class="size-6 cursor-pointer"
                        />
                    </div>
                    <div
                        *ngIf="isSendButtonOpen"
                        class="p-1 text-white flex items-center justify-center bg-secondary-500 rounded-full size-6 cursor-pointer"
                        (click)="sendMessage($event)"
                    >
                        <ng-icon name="ionPlay"></ng-icon>
                    </div>
                </div>

                <!-- Viewer List -->
                <div
                    *ngIf="isViewerListOpen"
                    @fadeToUp
                    class="fixed rounded-t-2xl left-0 top-1/2 flex flex-col w-full z-50 max-w-full h-1/2 max-h-[50%] px-4 bg-black bg-opacity-70 overflow-y-scroll backdrop-blur-md"
                >
                    <div class="flex flex-row items-center w-full justify-between">
                        <h3 class="text-white font-semibold text-xl py-4 mx-auto">Gifters & Viewers</h3>

                        <img
                            src="assets/images/shared/icons/white-close-icon.svg"
                            alt="Close"
                            class="w-6 h-6 cursor-pointer justify-self-end"
                            (click)="openViewerList()"
                        />
                    </div>
                    <app-live-stream-users
                        *ngFor="let user of partyUsers; index as i"
                        [index]="i"
                        [user]="user"
                    ></app-live-stream-users>
                </div>

                <!--- Gift List --->

                <div
                    *ngIf="isGiftListOpen && !isAgent"
                    @fadeToUp
                    class="fixed rounded-t-2xl left-0 top-1/2 flex flex-col w-full z-50 max-w-full h-1/2 max-h-[50%] px-4 bg-black bg-opacity-70 overflow-y-scroll backdrop-blur-md"
                >
                    <div class="flex flex-row items-center w-full justify-between">
                        <h3 class="text-white font-semibold text-xl py-4 mx-auto">Gifts</h3>

                        <img
                            src="assets/images/shared/icons/white-close-icon.svg"
                            alt="Close"
                            class="w-6 h-6 cursor-pointer justify-self-end"
                            (click)="openGiftList()"
                        />
                    </div>

                    <div class="grid grid-cols-4 w-full h-full">
                        <app-live-stream-gifts
                            class="items-center justify-center flex w-full h-full"
                            *ngFor="let gift of gifts"
                            [data]="gift"
                            (click)="sendGift(gift)"
                        ></app-live-stream-gifts>
                    </div>
                </div>

                <!--- overlay for viewerlist and gift list--->
                <div
                    *ngIf="isViewerListOpen || isGiftListOpen"
                    class="fixed inset-0 bg-black bg-opacity-50 z-40 w-full h-full"
                    (click)="isViewerListOpen ? openViewerList() : openGiftList()"
                ></div>
            </div>
        </div>
    </div>
</div>
