import {Component} from '@angular/core';
import {FollowButtonComponent} from '@shared/components/live-stream/follow-button/follow-button.component';

@Component({
    selector: 'app-viewer-profile',
    standalone: true,
    imports: [FollowButtonComponent],
    templateUrl: './viewer-profile.component.html',
    styleUrl: './viewer-profile.component.scss',
})
export class ViewerProfileComponent {}
