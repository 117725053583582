<div
    *ngIf="data !== null"
    class="flex flex-col w-16 h-20 rounded-xl items-center justify-center lg:w-auto lg:h-auto lg:hover:bg-[#1B1D21] lg:hover:bg-opacity-40 transition-all duration-300 ease-in-out py-4 cursor-pointer select-none snap-end"
>
    <img src="{{ data.url }}" alt="Gift" class="w-10 h-10" />
    <div class="flex flex-row py-2 items-center justify-center">
        <img src="assets/images/shared/crystals.svg" alt="Crystals" class="w-3 h-3" />
        <p class="text-xs text-white">{{ data.defaultFee }}</p>
    </div>
</div>
