export interface GiftDetail {
    id: 8;
    title: string;
    url: string;
    animation: string;
    superAnimation: string;
    superAnimationAndroid: string;
    defaultFee: number;
    defaultEarningRatio: number;
    createdAt: string;
    countriesFeatures: [];
    type: number;
}

export enum GiftType {
    POPULAR = 1,
    VIP = 2,
    AR = 3,
    LUCKY = 4,
}
