<div
    @fadeInOut
    class="flex flex-col max-w-[266px] grow h-auto p-2 bg-[#3f424A] bg-opacity-40 rounded-lg mt-[12px] justify-between select-none cursor-pointer lg:hover:bg-opacity-70 transition duration-300 ease-in-out lg:mt-2 lg:px-4"
>
    <p class="text-xs font-bold text-neutral-200">{{ featureData.title }}</p>
    <div class="w-full bg-[#7E8594] rounded-full h-2 my-1">
        <div
            class="bg-[#6FDB68] rounded-full h-2 transform transition-all duration-500 ease-in-out"
            style="width: {{ featureData.percentage }}%"
        ></div>
    </div>

    <div class="flex flex-row items-center">
        <img src="assets/images/shared/crystals.svg" alt="crystals" class="size-4" />
        <p class="ml-1 text-[10px] font-medium text-white">
            {{ featureData.currentValue }} / {{ featureData.targetValue }}
        </p>
    </div>
</div>
