<div class="circular-progress-bar-container">
    <svg class="circular-progress-bar-svg" width="80" height="80">
        <circle
            class="circular-progress-bar-background"
            [attr.stroke-width]="3"
            [attr.stroke]="backgroundColor"
            fill="transparent"
            [attr.r]="radius"
            cx="40"
            cy="40"
        />
        <circle
            class="circular-progress-bar-foreground"
            [attr.stroke-width]="3"
            [attr.stroke]="foregroundColor"
            fill="transparent"
            [attr.stroke-dasharray]="circumference"
            [attr.stroke-dashoffset]="circumference - (percent / 100) * circumference"
            stroke-linecap="round"
            [attr.r]="radius"
            cx="40"
            cy="40"
        />
    </svg>
    <span class="progress-label">{{ remainingTime }}</span>
</div>
