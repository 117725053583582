import {NgIf} from '@angular/common';
import {Component, Input, inject} from '@angular/core';
import {CommonResponse} from '@core/models/common/common-response';
import {UserService} from '@core/services/user/user.service';

@Component({
    selector: 'app-stream-follow-button',
    standalone: true,
    imports: [NgIf],
    templateUrl: './follow-button.component.html',
    styleUrl: './follow-button.component.scss',
})
export class FollowButtonComponent {
    @Input() userId!: number;
    @Input() userDetail!: {fullName: string; photo: string};
    userService = inject(UserService);

    showButton = true;

    followUser() {
        this.userService.followUser(this.userId, this.userDetail).subscribe((response: CommonResponse) => {
            if (response.success) {
                this.showButton = false;
            }
        });
    }
}
