<div
    @fadeInOut
    class="bg-opacity-40 bg-[#3F424A] max-w-[266px] w-full h-auto p-2 flex flex-wrap justify-center items-center md:justify-between rounded-lg mt-[16px] select-none cursor-pointer lg:hover:bg-opacity-70 transition duration-300 ease-in-out lg:mt-2 lg:py-[10px] lg:px-4"
>
    <p class="text-xs font-semibold text-neutral-200">{{ featureData.title }}</p>
    <div class="flex flex-row justify-center items-center">
        <img src="{{ featureData.gift.url }}" alt="rose-gift" class="size-6" />
        <div class="flex flex-row justify-center items-center py-2">
            <img src="assets/images/shared/crystals.svg" alt="crystals" class="size-4" />
            <p class="text-[10px] font-medium text-white">{{ featureData.price }}</p>
        </div>
    </div>
</div>
