import {Component, HostListener, Input, OnInit, inject} from '@angular/core';
import {NgIf} from '@angular/common';
import {Room} from '@core/models/candidate/room';
import {animate, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';
import {FollowButtonComponent} from '@core/components/ui-components/follow-button/follow-button.component';
import {GiftDetail} from '@core/models/gift/gift-detail';
import {ChatSocketService} from '@core/services/chat/chat-socket.service';
import {PartyJoinType} from '@core/enums/party/party-join-type.enum';
import {AuthService} from '@core/services';
import {ModalService} from '@core/services/modal/modal.service';
import {PurchaseComponent} from '@pages/purchase/purchase.component';

@Component({
    selector: 'app-moved-to-premium',
    standalone: true,
    imports: [NgIf, FollowButtonComponent],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: 0, transform: 'translateX(-30px)'}),
                animate('300ms', style({opacity: 1, transform: 'translateX(0)'})),
            ]),
        ]),
    ],
    templateUrl: './moved-to-premium.component.html',
    styleUrl: './moved-to-premium.component.scss',
})
export class MovedToPremiumComponent implements OnInit {
    screenWidth!: number;
    @Input() room!: Room;
    @Input() gift!: GiftDetail | undefined;

    auth = inject(AuthService);
    socketService = inject(ChatSocketService);
    modalService = inject(ModalService);

    constructor(private router: Router) {}
    ngOnInit() {
        this.screenWidth = window.innerWidth;
    }

    @HostListener('window:resize')
    onResize() {
        this.screenWidth = window.innerWidth;
    }

    routeToHome() {
        this.router.navigate(['./live']);
    }

    giftToJoin() {
        if (this.auth.appSession$.value!.userDetail!.balance >= this.gift!.defaultFee) {
            this.socketService.joinParty(this.room.roomId, PartyJoinType.UNLIMITED);
            window.location.reload();
        } else {
            this.modalService.open(PurchaseComponent);
        }
    }
}
