import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    inject,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import {LiveStreamChatComponent} from '@shared/components/live-stream-chat/live-stream-chat.component';
import {LiveStreamGiftsComponent} from '@shared/components/live-stream-gifts/live-stream-gifts.component';
import {CandidateService} from '@core/services/candidate/candidate.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PartyDetailResponse} from '../../core/models/candidate/party-detail-response';
import {Room} from '@core/models/candidate/room';
import {AgoraService} from '@core/services/agora/agora.service';
import {ChatService} from '@core/services/chat/chat.service';
import {IAgoraRTCClient, IRemoteVideoTrack} from 'agora-rtc-sdk-ng';
import {VideoTokenResponse} from '@core/models/chat/video-token-response';
import {AuthService} from '@core/services';
import {GiftDetail, GiftType} from '@core/models/gift/gift-detail';
import {GiftService} from '@core/services/gift/gift.service';
import {GiftsResponse} from '@core/models/gift/gifts-response';
import {ChatSocketService} from '@core/services/chat/chat-socket.service';
import {LiveStreamUsersComponent} from '@shared/components/live-stream-users/live-stream-users.component';
import {SimplebarAngularModule} from 'simplebar-angular';
import {animate, AUTO_STYLE, state, style, transition, trigger} from '@angular/animations';
import {GoalComponent} from '@shared/components/live-stream/party-feature/goal/goal.component';
import {StickerComponent} from '@shared/components/live-stream/party-feature/sticker/sticker.component';
import {PartyJoinType} from '@core/enums/party/party-join-type.enum';
import {PartyJoinResponseType} from '@core/enums/party/party-join-response-type.enum';
import {JoinResponseSocket} from '@core/models/chat/socket/join-response-socket';
import {PartyMessageSocket} from '@core/models/chat/socket/party-message-socket';
import {PartyUserSocket} from '@core/models/chat/socket/party-user-socket';
import {PartyInfoSocket} from '@core/models/chat/socket/party-info-socket';
import {IncomingPartyInfoCommandEvent} from '@core/enums/party/incoming-party-info-command.enum';
import {FormsModule} from '@angular/forms';
import {UserDetail} from '@core/models/user/user-detail';
import {ApiCommandSocket} from '@core/models/chat/socket/api-comman-socket';
import {IncomingApiCommandEvent} from '../../core/enums/incoming-api-command-event.enum';
import {ModalService} from '@core/services/modal/modal.service';
import {PurchaseComponent} from '@pages/purchase/purchase.component';
import {ViewerProfileComponent} from '../../shared/components/live-stream/viewer-profile/viewer-profile.component';
import {PartyFeatureSocket} from '@core/models/chat/socket/party-feature-socket';
import {GoalDetail} from '@core/models/stream-feature/goal-detail';
import {PartyFeatureType} from '@core/enums/stream-feature/party-feature-type.enum';
import {StickerDetail} from '@core/models/stream-feature/sticker-detail';
import _ from 'lodash';
import {PartyParticipantsResponse} from '@core/models/chat/party-participants-response';
import {PartyDataSocket} from '@core/models/chat/socket/party-data-socket';
import {FollowButtonComponent} from '@shared/components/live-stream/follow-button/follow-button.component';
import {UserService} from '@core/services/user/user.service';
import {CommonResponse} from '@core/models/common/common-response';
import {CommonService} from '@core/services/common/common.service';
import {AlertModalComponent} from '@shared/components/alert-modal/alert-modal.component';
import {i18nConstants} from '@core/constants/i18n-constants';
import {AgoraVideoContainerComponent} from '@shared/components/live-stream/agora-video-container/agora-video-container.component';
import {HostToolsOffComponent} from '@core/components/ui-components/stream/host-tools-off/host-tools-off.component';
import {StreamEndComponent} from '@shared/components/live-stream/stream-end/stream-end.component';
import {HostDisconnectComponent} from '@shared/components/live-stream/host-disconnect/host-disconnect.component';
import {PartyFinishSocket} from '@core/models/chat/socket/party-finish-socket';
import {StreamToolChangeSocket} from '@core/models/chat/socket/stream-tool-change-socket';
import {LottieComponent} from 'ngx-lottie';
import {PiPService} from '@core/services/common/pip.service';
import {RaceComponent} from '@shared/components/live-stream/party-feature/race/race.component';
import {RaceDetail} from '@core/models/stream-feature/race-detail';
import {PartyStickerState} from '@core/enums/party/party-sticker-state.enum';
import convertCoin from '@core/utils/helpers/coinConverter';
import {MovedToPremiumComponent} from '@shared/components/live-stream/premium/moved-to-premium/moved-to-premium.component';
import {PremiumStreamBadgeComponent} from '@shared/components/live-stream/premium/premium-stream-badge/premium-stream-badge.component';
import userPhotoConverter from '@core/utils/helpers/userPhotoConverter';
import {TopUserBorderComponent} from '@shared/components/live-stream/top-user-border/top-user-border.component';
import {PartyKickReason} from '@core/enums/party/party-kick-reason.enum';
import {MarqueeComponent, MarqueeItem, MarqueeList} from '@shared/components/marquee/marquee.component';
import AVideoPlayer from '@core/lib/alpha-video-player/player';
import {translate, TranslocoPipe} from '@ngneat/transloco';
import {ConstantService} from '@core/services/common/constant.service';
import {Subscription} from 'rxjs';
import {SendMessageResponseSocket} from '@core/models/chat/socket/send-message-response-socket';
import {SocketErrors} from '@core/enums/socket-errors.enum';
import {PartyPolicyType} from '@core/enums/party/party-policy-type.enum';
import {KickedUserComponent} from '@shared/components/live-stream/kicked-user/kicked-user.component';
import {AdminMessageTypes} from '@core/models/chat/admin-message-types';
import {PartyNoticeSocket} from '@core/models/chat/socket/party-notice-socket';
import {LivePartyListSocket} from '@core/models/chat/socket/live-party-list-socket';
import {environment} from '@env/environment';
import {PartyWheelDetail} from '@core/models/stream-feature/wheel-detail';
import {WheelComponent} from '@shared/components/live-stream/party-feature/wheel/wheel.component';
import translateReplacer from '@core/utils/helpers/translate-replacer';
import {RtcDataUser} from '@core/models/agora/rtc-data';
import {AgoraVideoService} from '@core/services/agora-video-service/agora-video-service.service';
import {
    GiftTab,
    LiveStreamGiftTabsComponent,
} from '@shared/components/live-stream/live-stream-gift-tabs/live-stream-gift-tabs.component';
import {PickerComponent} from '@ctrl/ngx-emoji-mart';
import {NgIcon, provideIcons} from '@ng-icons/core';
import {ionPlay} from '@ng-icons/ionicons';

@Component({
    selector: 'app-live-stream',
    standalone: true,
    templateUrl: './live-stream.component.html',
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: 0, transform: 'translateX(-30px)'}),
                animate('300ms', style({opacity: 1, transform: 'translateX(0)'})),
            ]),
        ]),
        trigger('fadeToUp', [
            transition(':enter', [
                style({opacity: 0, transform: 'translateY(30px)'}),
                animate('300ms', style({opacity: 1, transform: 'translateY(0)'})),
            ]),
            transition(':leave', [
                style({opacity: 1, transform: 'translateY(0)'}),
                animate('300ms', style({opacity: 0, transform: 'translateY(30px)'})),
            ]),
        ]),
        trigger('collapse', [
            state('false', style({opacity: AUTO_STYLE, scale: AUTO_STYLE, visibility: AUTO_STYLE})),
            state('true', style({opacity: 0, scale: 0, visibility: 'hidden'})),
            transition('false => true', animate('300ms ease-in')),
            transition('true => false', animate('300ms ease-out')),
        ]),
    ],
    styleUrl: './live-stream.component.scss',
    imports: [
        NgForOf,
        LiveStreamChatComponent,
        LiveStreamGiftsComponent,
        LiveStreamUsersComponent,
        SimplebarAngularModule,
        NgIf,
        GoalComponent,
        StickerComponent,
        FormsModule,
        ViewerProfileComponent,
        FollowButtonComponent,
        AgoraVideoContainerComponent,
        HostToolsOffComponent,
        StreamEndComponent,
        HostDisconnectComponent,
        LottieComponent,
        RaceComponent,
        MovedToPremiumComponent,
        PremiumStreamBadgeComponent,
        TopUserBorderComponent,
        MarqueeComponent,
        TranslocoPipe,
        WheelComponent,
        NgClass,
        LiveStreamGiftTabsComponent,
        PickerComponent,
        NgIcon,
    ],
    providers: [provideIcons({ionPlay})],
})
export class LiveStreamComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
    isViewerListOpen = false;
    isGiftListOpen = false;
    isChatOpen = false;

    isSendButtonOpen = false;

    screenWidth: number = window.innerWidth;
    screenWithLimitMobile = 1024;
    windowSizeSmall = false;
    showStream: boolean = false;
    isFirstTimeCheck = false;

    streamDisconnectStatus = false;
    streamFinishStatus = false;

    windowInnerWidth!: number;
    windowInnerHeight!: number;

    streamWidth!: number;
    streamHeight!: number;
    streamRatio!: number;
    remotePlayerContainer!: HTMLElement | null;
    resizeTimeout: string | number | NodeJS.Timeout | undefined;
    userVideoTrack!: IRemoteVideoTrack | undefined;
    streamResolutionStatus!: boolean;
    windowSizeChanged!: boolean;

    showMarquee = false;
    marqueeData: MarqueeItem = {} as MarqueeItem;
    marquees: MarqueeList[] = [];

    raceSizeChanged = false;
    remoteUsers: RtcDataUser[];
    giftSelectedRoomId!: number;
    giftSelectedTab: GiftTab = 'popular';
    currentGifts: GiftDetail[];

    channelName: string = '';

    isEmojiPickerOpen = false;

    @ViewChild('videoContainerFrame') videoContainerFrame: ElementRef | undefined;
    @ViewChild('videoContainerMobileFrame') videoContainerMobileFrame: ElementRef | undefined;
    @ViewChild('mainAgoraVideoController') mainAgoraVideoController: ElementRef | undefined;
    @ViewChild('giftTabs', {static: false}) giftTabs!: ElementRef;

    @ViewChild('viewerList', {static: false}) viewerList!: ElementRef;
    @ViewChild('viewerButton', {static: false}) viewerButton!: ElementRef;

    @ViewChild('chatInput', {static: false}) chatInput!: ElementRef;
    @ViewChildren('messages') messages!: QueryList<never>;
    @ViewChild('scrollableContainer') private scrollableContainer!: ElementRef;
    isViewerListLoading: boolean = true;

    agoraVideoService = inject(AgoraVideoService);

    @HostListener('window:focus', ['$event'])
    onFocus(): void {
        // Handle focus events
        if (this.stream.isDisconnected) {
            this.getVideoToken(this.room.roomId, this.room, true);
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
        this.resizeTimeout = setTimeout(
            (() => {
                this.screenWidth = window.innerWidth;

                const exStatus = this.windowSizeSmall;
                this.windowSizeSmall = !(this.screenWidth > 1024);

                if (this.windowSizeSmall) this.isGiftListOpen = false;

                if (exStatus != this.windowSizeSmall) {
                    this.windowSizeChanged = true;
                }

                this.windowInnerWidth = window.innerWidth;
                this.windowInnerHeight = window.innerHeight;

                const calculatedWidth = this.streamRatio * this.windowInnerHeight;

                if (calculatedWidth > this.windowInnerWidth) {
                    this.remotePlayerContainer!.style.width = '100%';
                } else {
                    if (this.remotePlayerContainer) this.remotePlayerContainer!.style.width = calculatedWidth + 'px';
                }
                this.remotePlayerContainer!.style.marginLeft = 'auto';
                this.remotePlayerContainer!.style.marginRight = 'auto';
            }).bind(this),
            200,
        );
    }

    auth = inject(AuthService);
    route = inject(ActivatedRoute);
    candidateService = inject(CandidateService);
    stream = inject(AgoraService);
    chatService = inject(ChatService);
    giftService = inject(GiftService);
    // socketService = inject(ChatSocketService);
    modalService = inject(ModalService);
    userService = inject(UserService);
    commonService = inject(CommonService);
    router = inject(Router);
    constantService = inject(ConstantService);

    pipService = inject(PiPService);

    isAgent = false;

    currentUser: UserDetail;
    roomId: number | undefined;
    room!: Room;
    gifts: GiftDetail[];
    chatMessages: PartyMessageSocket[];
    partyUsers: PartyUserSocket[];
    bestSupporters: PartyUserSocket[];
    bestSupportersIdList: number[];

    goalData!: GoalDetail;
    stickerData!: StickerDetail;
    raceData!: RaceDetail;
    partyWheelData!: PartyWheelDetail;
    isRaceFinished = true;
    isGoalFinished = true;
    isStickerFinished = true;
    isPartyWheelFinished = true;
    showPartyWheelSpin = false;

    socketConnectionState = false;
    participantCount = 0;
    partyMessageContent = '';
    muteVideo = false;
    blockedWords: string[];

    currentRoomData!: Room;
    showPremiumPage = false;
    premiumGift!: GiftDetail | undefined;

    animationQue: {giftId: number; roomId: number}[];

    alphaVideoPlayer!: AVideoPlayer;
    animationControl: {[key: number]: boolean};

    roomPhoto: string = '';
    isLoading = true;

    remoteUser: RtcDataUser | undefined;

    socketJoinPartySub!: Subscription;
    socketApiCommandSub!: Subscription;
    socketJoinResponseSub!: Subscription;
    socketPartyMessageSub!: Subscription;
    socketPartyFeatureSub!: Subscription;
    socketFinishSub!: Subscription;
    socketPartyInfoSub!: Subscription;

    isMeMuted = false;

    constructor(
        private cdr: ChangeDetectorRef,
        public socketService: ChatSocketService,
    ) {
        this.animationQue = [];
        this.chatMessages = [];
        this.partyUsers = [];
        this.bestSupporters = [];
        this.bestSupportersIdList = [];
        this.animationControl = [];
        this.blockedWords = [];
        this.remoteUsers = [];
        this.currentGifts = [];
        this.showPartyWheelSpin = !environment.production;
        const routerState = this.router.getCurrentNavigation()?.extras.state;
        if (routerState) {
            const {roomPhoto} = routerState;
            this.roomPhoto = roomPhoto ? roomPhoto : 'assets/images/shared/logo.svg';
        } else {
            this.roomPhoto = 'assets/images/shared/logo.svg';
        }
        this.auth.isAuthenticated$.subscribe((isAuthenticated: boolean) => {
            if (!isAuthenticated) {
                this.router.navigate(['/live']);
            }
        });

        this.currentUser = this.auth.appSession$.value!.userDetail!;

        const id = this.route.snapshot.paramMap.get('id');
        this.roomId = Number(this.auth.decryptCommon(id!));
        this.gifts = [];

        this.socketService.isConnected$.subscribe((isconnected: boolean) => {
            if (isconnected) {
                this.socketConnectionState = isconnected;
            }
        });

        this.windowSizeChanged = false;
        this.screenWidth = window.innerWidth;
        if (this.screenWidth < this.screenWithLimitMobile) {
            this.windowSizeSmall = true;
        }
    }

    ngAfterViewChecked(): void {
        if (this.windowSizeChanged || this.isFirstTimeCheck) {
            if (this.windowSizeChanged && this.windowSizeSmall) {
                this.windowSizeChanged = false;
                this.streamResolutionStatus = false;
                this.isFirstTimeCheck = true;
                window.location.reload();
            }

            this.handleVideoResolution();
            this.cdr.detectChanges();
            setTimeout(() => {
                this.isFirstTimeCheck = false;
            }, 10000);
        }

        // this.cdr.detectChanges();
    }

    handleVideoResolution() {
        if (!this.windowSizeSmall) {
            this.remotePlayerContainer = this.videoContainerFrame?.nativeElement;
        } else {
            this.remotePlayerContainer = this.videoContainerMobileFrame?.nativeElement;
        }

        if (
            this.remotePlayerContainer != undefined &&
            this.userVideoTrack != undefined &&
            !this.streamResolutionStatus &&
            this.stream.remoteUsers.value.length == 1
        ) {
            const userTrack = this.userVideoTrack!.getMediaStreamTrack().getSettings();
            if (userTrack.height && userTrack.width && userTrack?.height > 0 && userTrack?.width > 0) {
                this.streamWidth = userTrack?.width;
                this.streamHeight = userTrack?.height;
                if (this.streamWidth > this.streamHeight) {
                    this.streamRatio = this.streamHeight / this.streamWidth;
                } else {
                    this.streamRatio = this.streamWidth / this.streamHeight;
                }

                const calculatedWidth = this.streamRatio * this.windowInnerHeight;
                if (calculatedWidth > this.windowInnerWidth) {
                    this.remotePlayerContainer!.style.width = '100%';
                } else {
                    this.remotePlayerContainer!.style.width = calculatedWidth + 'px';
                }
                this.remotePlayerContainer!.style.marginLeft = 'auto';
                this.remotePlayerContainer!.style.marginRight = 'auto';

                this.streamResolutionStatus = true;
                this.showStream = true;
                this.raceSizeChanged = true;
            }
        }
        if (this.stream.remoteUsers.value.length > 1) this.streamResolutionStatus = true;
    }

    ngAfterViewInit(): void {
        this.messages.changes.subscribe(() => {
            if (!this.isViewerListOpen) {
                const scrollThreshold = 80;
                const isOnBottom = this.scrollableContainer?.nativeElement.scrollTop + scrollThreshold >= 0;
                if (isOnBottom) {
                    this.scrollableContainer.nativeElement.scrollTop =
                        this.scrollableContainer.nativeElement.scrollHeight;
                }
            }
        });
        this.currentUser = this.auth.appSession$.value!.userDetail!;
        this.streamWidth = 0;
        this.streamHeight = 0;
        this.windowInnerWidth = window.innerWidth;
        this.windowInnerHeight = window.innerHeight;
    }

    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
    ngOnDestroy(): void {
        this.socketJoinPartySub?.unsubscribe();

        this.socketApiCommandSub?.unsubscribe();
        this.socketJoinResponseSub?.unsubscribe();
        this.socketPartyMessageSub?.unsubscribe();
        this.socketPartyFeatureSub?.unsubscribe();
        this.socketFinishSub?.unsubscribe();
        this.socketPartyInfoSub?.unsubscribe();

        if (!this.streamFinishStatus) {
            this.pipService.pipData$.next({
                room: this.room,
                service: this.stream,
                mute: this.muteVideo,
                isFinished: false,
                isPremium: this.showPremiumPage,
                channelName: this.channelName,
            });
        } else {
            this.pipService.pipData$.next({
                room: this.room,
                service: this.stream,
                mute: this.muteVideo,
                isFinished: true,
                isPremium: this.showPremiumPage,
                channelName: this.channelName,
            });
        }
        window.document.documentElement.style.overflow = 'auto';
    }

    ngOnInit(): void {
        this.streamFinishStatus = false;
        this.streamDisconnectStatus = false;
        this.isFirstTimeCheck = true;

        this.streamResolutionStatus = false;

        window.document.documentElement.style.overflow = 'hidden';
        this.isAgent = this.auth.appSession$.value!.userDetail!.userLevel != 0;

        this.getGifts();
        if (this.auth.configSession$.getValue() != null) {
            this.blockedWords = this.auth.configSession$.value!.blockedWordsArray;
        }
        if (this.roomId != undefined) {
            this.chatService.getPartyParticipants(this.roomId).subscribe((data: PartyParticipantsResponse) => {
                if (data.success) {
                    this.partyUsers.push(...data.participants);

                    this.socketService.isConnected$.subscribe((isconnected: boolean) => {
                        if (isconnected) {
                            this.listenSocketPreJoin();
                            this.socketService.joinParty(this.roomId!, PartyJoinType.FREEMIUM);

                            this.getStreamDetail();

                            this.socketJoinResponseSub = this.socketService
                                .onJoinResponse()
                                .subscribe((data: string) => {
                                    const res: JoinResponseSocket = JSON.parse(data);
                                    if (res.result == PartyJoinResponseType.SUCCESS) {
                                        this.listenSockets();
                                        this.showPremiumPage = false;
                                    } else if (res.result == PartyJoinResponseType.NEED_TICKET) {
                                        this.listenLiveParties();
                                        this.showPremiumPage = true;
                                        this.premiumGift = this.gifts.find(gift => gift.defaultFee == res.cost);
                                    } else if (res.result === PartyJoinResponseType.NOT_ENOUGH_BALANCE) {
                                        this.openPurchaseModal();
                                    } else if (res.result === PartyJoinResponseType.PARTY_IS_FINISHED) {
                                        this.isRaceFinished = true;
                                        this.finishAndRedirect();
                                    }
                                });
                        }
                    });

                    this.stream.remoteUsers.subscribe((users: RtcDataUser[]) => {
                        this.remoteUsers = users;
                        this.remoteUsers.sort(a => (a.userId == this.room.id ? -1 : 1));
                        this.giftSelectedRoomId = this.roomId || 0;
                        this.remoteUser = users.find(user => user.userId == this.room.id);
                        if (this.remoteUser?.data.videoTrack != undefined) {
                            this.userVideoTrack = this.remoteUser?.data.videoTrack;
                        }

                        if (this.remoteUser?.data.audioTrack != undefined) {
                            if (this.stream.clientMuted) {
                                if (this.remoteUser?.data.audioTrack.getVolumeLevel() != 0) {
                                    this.remoteUser?.data.audioTrack.setVolume(0);
                                }
                            } else {
                                if (this.remoteUser?.data.audioTrack.getVolumeLevel() != 60) {
                                    this.remoteUser?.data.audioTrack.setVolume(60);
                                    this.remoteUser?.data.audioTrack.play();
                                }
                            }
                        }
                    });
                }
            });
        }
        this.isGiftListOpen = window.innerWidth > 1024;
    }

    openViewerList() {
        if (this.streamFinishStatus) return;

        if (this.isViewerListOpen) {
            this.isViewerListOpen = false;
            return;
        }

        this.isViewerListOpen = true;

        this.isViewerListLoading = true;
        this.chatService.getPartyParticipants(this.roomId!).subscribe((data: PartyParticipantsResponse) => {
            if (data.success) {
                const combinedArray = this.partyUsers.map(el => ({
                    ...el,
                    ...data.participants.find(arr1El => arr1El.id === el.id),
                }));

                this.partyUsers = combinedArray;

                this.partyUsers.forEach((user: PartyUserSocket, index: number) => {
                    if (user.id === this.currentUser.id) {
                        this.partyUsers[index].isFollowing = true;
                    }
                });
                this.partyUsers.sort((a, b) => b.spending - a.spending);
                this.isViewerListLoading = false;
            }
        });
        return;
    }

    openGiftList() {
        this.isGiftListOpen = !this.isGiftListOpen;
    }

    finishAndRedirect() {
        this.streamFinishStatus = true;
        this.clearLiveStreamVideos(this.roomId!);
        this.routeToHome();
        //this.router.navigate(['/live']);
    }

    getParticipants(roomId: number) {
        this.chatService.getPartyParticipants(roomId).subscribe((data: PartyParticipantsResponse) => {
            if (data.success) {
                this.partyUsers.push(...data.participants);
            }
        });
    }

    getStreamDetail() {
        this.candidateService.getPartyDetail(this.roomId!).subscribe((data: PartyDetailResponse) => {
            if (data.success) {
                this.room = data.room;
                this.isLoading = false;
                this.getVideoToken(this.room.roomId, this.room);
            }
        });
    }

    sendMessage(event: Event) {
        event.preventDefault();
        const inputElement = document.getElementById('text-input') as HTMLTextAreaElement;

        let message = this.partyMessageContent.trim();
        if (this.isMeMuted && message.length > 0) {
            this.handleAdminMuteMessage(true, this.currentUser.id);
        }

        if (message.length > 0 && this.filterMessageByBlockedwords(message)) {
            message = this.manipulateMessage(message);

            this.socketService.chatSocket.emit('partyMessage', {m: message, roomId: this.roomId}, (data: string) => {
                let resData;
                if (typeof data === 'object') {
                    resData = data;
                } else {
                    resData = JSON.parse(data);
                }

                const resChat: SendMessageResponseSocket = resData;

                switch (resChat.errorCode) {
                    case SocketErrors.COMMENT_NOT_ALLOWED:
                        // eslint-disable-next-line no-case-declarations
                        this.handleAdminNoticeMessage(PartyPolicyType[resChat.errorType], true);

                        /*const chatMessage: PartyMessageSocket = {
                            roomId: this.roomId?.toString() || '0',
                            rId: 0,
                            sId: 0,
                            t: 0,
                            me: '',
                            m: PartyPolicyType[resChat.errorType],
                            gId: 0,
                            isWarning: true,
                            warningContent: PartyPolicyType[resChat.errorType],
                        };
                        this.chatMessages.push(chatMessage);*/
                        break;
                }
            });

            //this.socketService.partyMessage({m: message});
            this.partyMessageContent = '';
        } else {
            if (inputElement) inputElement.value = '';
        }
        if (inputElement) inputElement.style.height = 'auto';
    }

    manipulateMessage(message: string) {
        message = message.replace(/\n+/g, '\n');
        message = message.replace(/\s+/g, ' ');
        message = message.replace(/\n/g, ' ');
        return message.trim();
    }

    filterMessageByBlockedwords(message: string): boolean {
        const containWords = this.blockedWords.filter(function (word) {
            const regex = new RegExp(`(^|\\s)${word}(\\s|$)`, 'i');
            const contains = regex.test(message);
            return contains;
        });

        return containWords.length <= 0;
    }

    //ANIMATION GIFT
    handleAnimation(giftId: number, roomId: number) {
        const currentGift = this.gifts.find(gift => gift.id === giftId);
        if (currentGift && currentGift.type == 3) {
            this.animationControl[roomId] = true;
            setTimeout(() => {
                this.handleAnimationQueue(roomId);
            }, 4000);
            return;
        }

        if (!this.streamFinishStatus) {
            const gift = this.gifts.filter(function (gift) {
                return gift.id == giftId;
            })[0];
            if (gift) {
                this.animationControl[roomId] = true;
                const giftContainerId = `${roomId}-gift-animation`;
                const containerElement = document.getElementById(giftContainerId) as HTMLElement;
                this.alphaVideoPlayer = new AVideoPlayer({
                    src: gift.superAnimation,
                    speed: 1,
                    loop: false,
                    autoplay: false,
                    muted: false,
                    volume: 0.1,
                    container: containerElement,
                    orientation: 'landscape',
                    stencilOrder: 'back',
                    onEnded: () => {
                        this.onAnimationVideoEnd(roomId);
                    },
                    onError() {
                        console.log('animation error');
                    },
                    canPlay: this.canAnimationPlay,
                });
            }
        }
    }

    onAnimationVideoEnd = (roomId: number) => {
        const containerElement = document.querySelector('.containerGiftAnimation') as HTMLElement;
        if (containerElement) {
            containerElement.innerHTML = '';
        }
        this.alphaVideoPlayer.destroy();

        this.handleAnimationQueue(roomId);
    };

    handleAnimationQueue(roomId: number) {
        if (this.animationQue.length > 0) {
            const queue = this.animationQue.filter(gift => gift.roomId === roomId);
            if (queue.length > 0) {
                const giftData = queue.shift();
                if (giftData) {
                    this.handleAnimation(giftData.giftId, giftData.roomId);
                    this.animationQue.splice(this.animationQue.indexOf(giftData), 1);
                }
            } else {
                this.animationControl[roomId] = false;
            }
        } else {
            this.animationControl[roomId] = false;
        }
    }

    canAnimationPlay = () => {
        this.alphaVideoPlayer.play();
    };

    //START AGORA
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    startCall(token: string, room: string, uid: number, roomId: number, refreshAgoraView: boolean = false) {
        if (
            this.stream.rtcClient &&
            this.stream.rtcClient.connectionState === 'CONNECTED' &&
            this.pipService.pipData$.getValue().room != undefined &&
            this.pipService.pipData$.getValue().room.roomId === this.roomId
        ) {
            this.showStream = true;
        } else {
            if (this.stream.rtcClient != undefined) {
                this.stream.leaveStream().finally(() => {
                    this.stream.clearRemoteUsers();
                    this.stream.createRtcClient();
                    this.stream.localUser(room, token, uid).then(() => {
                        this.showStream = true;
                        if (refreshAgoraView) {
                            this.agoraVideoService.callHandleWindowFocused();
                        }
                    });
                });
            } else {
                this.stream.createRtcClient();
                this.stream.localUser(room, token, uid).then(() => {
                    this.showStream = true;
                    if (refreshAgoraView) {
                        this.agoraVideoService.callHandleWindowFocused();
                    }
                });
            }

            /*this.stream.leaveStream().finally(() => {
                this.stream.rtcData = {};
                this.stream.createRtcClient();
                this.stream.localUser(room, token, uid).then(() => {
                    this.showStream = true;
                });
            });*/
        }
    }

    toogleMute() {
        if (this.remoteUser && this.remoteUser.data && this.remoteUser.data.audioTrack) {
            this.stream.toggleMute(this.room.id);
            if (this.remoteUser?.isMuted) {
                this.remoteUser.data.audioTrack?.setVolume(0);
                this.muteVideo = true;
            } else {
                this.remoteUser?.data.audioTrack?.setVolume(60);
                this.remoteUser?.data.audioTrack?.play();
                this.muteVideo = false;
            }
        }
    }

    getVideoToken(roomId: number, video: Room, refreshAgoraView: boolean = false) {
        this.chatService.generateVideoToken(roomId).subscribe({
            next: (data: VideoTokenResponse) => {
                if (data.success) {
                    if (data.videoToken != null) {
                        const userId = this.auth.appSession$.getValue()?.user;
                        const numberUserId: number = +userId!;
                        video.token = data.videoToken;
                        video.watcherId = numberUserId;
                        this.currentRoomData = video;
                        this.channelName = data.channelName;
                        this.startCall(
                            data.videoToken,
                            this.channelName,
                            video.watcherId,
                            video.roomId,
                            refreshAgoraView,
                        );
                    }
                }
            },
        });
    }

    getRenewToken(roomId: number, client: IAgoraRTCClient) {
        this.chatService.renewVideoToken(roomId).subscribe({
            next: (data: VideoTokenResponse) => {
                if (data.success) {
                    if (data.videoToken != null) {
                        client.renewToken(data.videoToken);
                    }
                }
            },
        });
    }

    clearLiveStreamVideos(roomId: number) {
        const streamView = document.getElementById('video-container-' + roomId);
        //this.stream.rtcData.client?.leave();
        streamView?.remove();
    }
    //END AGORA

    followStreamer(userId: number) {
        this.userService
            .followUser(userId, {fullName: this.room.fullName, photo: this.room.photo})
            .subscribe((data: CommonResponse) => {
                if (data.success) {
                    this.room.isFollowing = true;
                }
            });
    }

    //GIFTS
    getGifts() {
        this.giftService.getGifts().subscribe((data: GiftsResponse) => {
            if (data.success) {
                this.gifts = data.gifts;

                this.gifts.sort((a, b) => a.defaultFee - b.defaultFee);
                this.currentGifts = this.gifts.filter(gift => gift.type == GiftType.POPULAR);
            }
        });
    }

    sendGift(gift: GiftDetail) {
        if (this.isAgent || this.streamFinishStatus) return;
        if (this.auth.userDetail.balance >= gift.defaultFee) {
            this.socketService.partyMessage({gId: gift.id, roomId: this.giftSelectedRoomId!});
        } else {
            this.modalService.open(PurchaseComponent);
        }
    }

    filterGiftById(giftId: number): GiftDetail {
        return this.gifts.filter(function (gift) {
            return gift.id == giftId;
        })[0];
    }
    //END GIFTS

    //SOCKET
    listenSockets() {
        this.socketService.chatSocket.emit('partyData', {id: 0}, (data: PartyDataSocket) => {
            this.updateBestSupporters(data.bestSupporters);
            data.partyFeature.forEach((feature: PartyFeatureSocket) => {
                this.updateFeatures(feature);
            });
        });

        this.socketPartyMessageSub = this.socketService.onJoinPartyMessage().subscribe((data: string) => {
            const resMessage: PartyMessageSocket = JSON.parse(data);
            if (resMessage.gId) {
                this.updateGoal(resMessage.gId);
                this.updateViewerList(resMessage.gId, resMessage.sId);

                if (this.animationControl[parseInt(resMessage.roomId)]) {
                    this.animationQue.push({giftId: resMessage.gId, roomId: parseInt(resMessage.roomId)});
                } else {
                    this.handleAnimation(resMessage.gId, parseInt(resMessage.roomId));
                }
            }
            this.chatMessages.push(resMessage);
        });

        this.socketPartyFeatureSub = this.socketService.onPartyFeatureV2().subscribe((data: string) => {
            const resFeature: PartyFeatureSocket = JSON.parse(data)[0];
            this.updateFeatures(resFeature);
        });

        this.socketFinishSub = this.socketService.onFinishParty().subscribe((data: string) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const resData: PartyFinishSocket = JSON.parse(data);

            this.handleFinishParty();
        });

        this.socketService.onStreamToolChange().subscribe((data: string) => {
            const resData: StreamToolChangeSocket = JSON.parse(data);
            if (this.room?.roomId === resData.roomId) {
                this.room = {...this.room, ...resData.data};
            }
        });

        this.socketService.onRoomNotice().subscribe((data: string) => {
            const resData: PartyNoticeSocket = JSON.parse(data);
            const message = resData.msg;
            this.handleAdminNoticeMessage(message, false);
        });

        this.socketPartyInfoSub = this.socketService.onPartyInfo().subscribe((data: string) => {
            const resParty: PartyInfoSocket = JSON.parse(data);

            switch (resParty.cmd) {
                case IncomingPartyInfoCommandEvent.PARTICIPANT_COUNT:
                    this.participantCount = resParty.value;
                    break;
                case IncomingPartyInfoCommandEvent.BEST_SUPPORTERS:
                    this.updateBestSupporters(resParty.values);
                    break;
                case IncomingPartyInfoCommandEvent.EARNED_COINS:
                    this.updateStreamerBalance(resParty.value);
                    break;
                case IncomingPartyInfoCommandEvent.KICK_PARTICIPANT:
                    if (resParty.reason === PartyKickReason.KICK_REASON_PRO_PARTY) {
                        this.premiumGift = this.gifts.find(gift => gift.defaultFee == resParty.fee);
                        this.showStream = false;
                        this.showPremiumPage = true;
                        this.listenLiveParties();
                        //this.socketService.joinParty(this.roomId!, PartyJoinType.FREEMIUM);
                    } else if (resParty.reason === PartyKickReason.KICK_REASON_AGENT) {
                        if (!this.socketService.bannedRooms.includes(this.room.roomId)) {
                            this.socketService.bannedRooms.push(this.room.roomId);
                        }
                        this.modalService.open(KickedUserComponent, {backdrop: true, position: 'center'});
                        this.finishAndRedirect();
                    }
                    break;
                case IncomingPartyInfoCommandEvent.GIFT_BROADCAST:
                    if (this.showMarquee) {
                        this.marquees.push({
                            index: this.marquees.length,
                            item: JSON.parse(resParty.broadcastInfo!),
                        });
                        return;
                    }
                    this.marqueeData = JSON.parse(resParty.broadcastInfo!);
                    this.showMarquee = true;
                    break;
                case IncomingPartyInfoCommandEvent.MUTE_PARTICIPANT:
                    if (resParty.value === this.currentUser.id && this.isMeMuted) return;
                    this.handleAdminMuteMessage(true, resParty.value);
                    break;
                case IncomingPartyInfoCommandEvent.UN_MUTE_PARTICIPANT:
                    this.handleAdminMuteMessage(false, resParty.value);
                    break;
            }
        });
    }

    handleFinishParty() {
        this.showPremiumPage = false;
        this.streamDisconnectStatus = false;
        this.streamFinishStatus = true;
        this.animationQue = [];
        this.alphaVideoPlayer?.destroy();
        this.isRaceFinished = true;
        this.showPartyWheelSpin = false;
        this.showMarquee = false;
        this.clearLiveStreamVideos(this.roomId!);
    }

    listenLiveParties() {
        this.socketService.onLivePartyList().subscribe((data: string) => {
            const resParties: LivePartyListSocket = JSON.parse(data);

            if (resParties.parties.length > 0) {
                const currentRoom = resParties.parties.find(party => party == this.roomId);
                if (!currentRoom) {
                    this.handleFinishParty();
                }
            } else {
                this.handleFinishParty();
            }
        });
    }

    listenSocketPreJoin() {
        this.socketJoinPartySub = this.socketService.onJoinParty().subscribe((data: string) => {
            const resUser: PartyUserSocket = JSON.parse(data);
            resUser.id = resUser.userId;
            const user = this.filterUserById(resUser.userId);
            if (user === undefined) {
                this.partyUsers.push(resUser);
            }
            const chatMessage: PartyMessageSocket = {
                roomId: this.roomId?.toString() || '0',
                rId: resUser.userId,
                sId: resUser.userId,
                t: 5,
                me: '',
                m: resUser.fullName,
                gId: 0,
            };
            const joinMessage = this.chatMessages.find(msg => {
                return msg.t === 5;
            });
            joinMessage && this.chatMessages.splice(this.chatMessages.indexOf(joinMessage), 1);
            this.chatMessages.push(chatMessage);
        });

        this.socketApiCommandSub = this.socketService.onApiCommands().subscribe((data: string) => {
            const resCommand: ApiCommandSocket = JSON.parse(data);

            switch (resCommand.cmd) {
                case IncomingApiCommandEvent.KICK_ACCOUNT:
                    this.auth.kickTheAccount();
                    break;
                case IncomingApiCommandEvent.REMAINING_COIN:
                    this.auth.updateUserBalance(resCommand.value);
                    break;
                case IncomingApiCommandEvent.IAP_SUCCEEDED:
                    this.modalService.closeAndOpen(AlertModalComponent, {
                        data: {
                            title: translate(i18nConstants.IAP_SUCCEED_TITLE),
                            description: translate(i18nConstants.IAP_SUCCEED_DESC),
                        },
                    });
                    break;
                case IncomingApiCommandEvent.HOST_DISCONNECTED:
                    this.streamDisconnectStatus = true;
                    break;
                case IncomingApiCommandEvent.HOST_IS_BACK:
                    this.streamDisconnectStatus = false;
                    break;
            }
        });
    }
    //END SOCKET

    updateFeatures(featureData: PartyFeatureSocket) {
        let percentage = 0;

        switch (featureData.type) {
            case PartyFeatureType.GOAL:
                if (featureData.state == PartyStickerState.CREATED || featureData.state == PartyStickerState.UPDATED) {
                    percentage = (featureData.data.t / featureData.data.g) * 100;

                    this.goalData = {
                        title: featureData.data.m,
                        currentValue: featureData.data.t,
                        targetValue: featureData.data.g,
                        percentage: percentage > 100 ? 100 : percentage,
                    };
                    this.isGoalFinished = false;
                }

                if (featureData.state == PartyStickerState.DELETED || featureData.state == PartyStickerState.CANCELED) {
                    setTimeout(() => {
                        this.isGoalFinished = true;
                    }, 1000);
                }
                break;
            case PartyFeatureType.STICKER:
                {
                    if (
                        featureData.state == PartyStickerState.CREATED ||
                        featureData.state == PartyStickerState.UPDATED
                    )
                        this.isStickerFinished = false;
                    const gift: GiftDetail = this.filterGiftById(featureData.data.g);
                    this.stickerData = {
                        title: featureData.data.m,
                        gift: gift,
                        price: gift.defaultFee,
                    };
                    if (
                        featureData.state == PartyStickerState.DELETED ||
                        featureData.state == PartyStickerState.CANCELED
                    ) {
                        this.isStickerFinished = true;
                    }
                }
                break;
            case PartyFeatureType.RACE:
                this.raceData = {
                    top3: featureData.data.top3,
                    timeLeft: featureData.data.t,
                    state: featureData.state,
                };
                if (featureData.state == 0 || featureData.state == 1) this.isRaceFinished = false;
                break;
            case PartyFeatureType.PARTY_WHEEL:
                if (featureData.state == PartyStickerState.CREATED || featureData.state == PartyStickerState.UPDATED)
                    this.isPartyWheelFinished = false;
                this.partyWheelData = {
                    actions: featureData.data.actions,
                    result: featureData.data.r,
                    userId: featureData.data.userId,
                    price: featureData.data.p,
                    id: featureData.data.wheelId,
                };

                if (featureData.state == PartyStickerState.DELETED || featureData.state == PartyStickerState.CANCELED) {
                    this.isPartyWheelFinished = true;
                }
                break;
        }
    }

    updateGoal(giftId: number) {
        const stickerGift = this.gifts.filter(function (gift) {
            return gift.id == giftId;
        })[0];

        if (stickerGift) {
            if (this.goalData) {
                this.goalData.currentValue += stickerGift.defaultFee;
                if (this.goalData.currentValue > this.goalData.targetValue) {
                    this.goalData.currentValue = this.goalData.targetValue;
                }
                this.goalData.percentage = (this.goalData.currentValue / this.goalData.targetValue) * 100;
                this.goalData.percentage = this.goalData.percentage > 100 ? 100 : this.goalData.percentage;
            }
        }
    }

    updateViewerList(giftId: number, senderId: number) {
        const gift = this.gifts.filter(function (gift) {
            return gift.id == giftId;
        })[0];

        const user = this.partyUsers.filter(function (user) {
            return user.id == senderId;
        })[0];

        if (gift && user) {
            user.spending = user.spending + gift.defaultFee;
        }
    }

    updateBestSupporters(datas: number[]) {
        const diffArray = _.difference(datas, this.bestSupportersIdList);

        if (diffArray.length > 0) {
            for (let i = 0; i < datas.length; i++) {
                const foundUser = this.filterUserById(datas[i]);
                if (foundUser) {
                    this.bestSupporters[i] = foundUser;
                    if (this.bestSupporters[i].photo == null) {
                        this.bestSupporters[i].photo = userPhotoConverter(this.bestSupporters[i].id);
                    }
                    this.bestSupportersIdList[i] = datas[i];
                }
            }
        }
    }

    updateStreamerBalance(newBalance: number) {
        this.room.earnedCoin = newBalance;
    }

    sendStickerGift(stickerData: StickerDetail) {
        if (this.isAgent || this.streamFinishStatus) return;
        if (this.auth.userDetail.balance >= stickerData.price) {
            this.socketService.partyMessage({gId: stickerData.gift.id});
        } else {
            this.modalService.open(PurchaseComponent);
        }
    }

    filterUserById(userId: number): PartyUserSocket {
        return this.partyUsers.filter(function (user) {
            return user.id == userId;
        })[0];
    }

    openChat() {
        this.isChatOpen = !this.isChatOpen;
        if (this.isChatOpen) {
            setTimeout(() => {
                const inputElement = document.getElementById('chat-input');
                inputElement?.focus();
            }, 200);
        }
    }

    openPurchaseModal() {
        this.modalService.open(PurchaseComponent);
    }

    routeToHome() {
        if (!this.streamFinishStatus) {
            this.pipService.pipData$.next({
                room: this.room,
                service: this.stream,
                mute: this.muteVideo,
                isFinished: false,
                isPremium: this.showPremiumPage,
                channelName: this.channelName,
            });
        } else {
            this.pipService.pipData$.next({
                room: this.room,
                service: this.stream,
                mute: this.muteVideo,
                isFinished: true,
                isPremium: this.showPremiumPage,
                channelName: this.channelName,
            });
        }
        this.router.navigate(['/live']);
    }

    adjustTextareaHeight(event: Event) {
        if (!this.isSendButtonOpen) this.isSendButtonOpen = true;
        if (this.isSendButtonOpen && this.partyMessageContent.length == 0) this.isSendButtonOpen = false;
        const textarea = event.target as HTMLTextAreaElement;
        textarea.style.height = 'auto';
        textarea.style.height = Math.min(textarea.scrollHeight, 100) + 2 + 'px'; // Assuming 100px is the max height
    }

    handleMobileChatInput() {
        if (!this.isSendButtonOpen) this.isSendButtonOpen = true;
        if (this.isSendButtonOpen && this.partyMessageContent.length == 0) this.isSendButtonOpen = false;
    }

    handleRaceFinish() {
        this.isRaceFinished = true;
    }

    onMarqueeEnd() {
        this.showMarquee = false;
        if (this.marquees && this.marquees.length > 0) {
            const nextMarquee = this.marquees.shift();
            this.marqueeData = nextMarquee!.item;
            setTimeout(() => {
                this.showMarquee = true;
            }, 1000);
        }
    }
    @HostListener('document:click', ['$event'])
    clickout(event: Event) {
        if (this.isViewerListOpen && !this.windowSizeSmall) {
            if (
                !this.viewerList.nativeElement.contains(event.target) &&
                !this.viewerButton.nativeElement.contains(event.target)
            ) {
                this.isViewerListOpen = false;
            }
        }
    }

    createAdminMessage(msg: string, type: AdminMessageTypes) {
        const message: PartyMessageSocket = {
            roomId: this.roomId?.toString() || '0',
            rId: 0,
            sId: 0,
            t: type,
            me: '',
            m: msg,
            gId: 0,
            isAdmin: true,
        };
        return message;
    }

    handleAdminMuteMessage(isMute: boolean, userId: number) {
        const isMe = userId === this.currentUser.id;
        let msgType;
        let msgLocalize;
        const fullName = this.partyUsers.find(user => user.id === userId)?.fullName;
        if (isMute) {
            msgType = isMe ? AdminMessageTypes.MUTE_ME : AdminMessageTypes.MUTE_OTHER;
            msgLocalize = isMe
                ? translate(i18nConstants.WatchPartyVC_YouAreMuted)
                : translateReplacer(translate(i18nConstants.StreamVC_UserMuted), {xxx: fullName || ''});
        } else {
            msgType = isMe ? AdminMessageTypes.UNMUTE_ME : AdminMessageTypes.UNMUTE_OTHER;
            msgLocalize = isMe
                ? translate(i18nConstants.WatchPartyVC_YouAreUnmuted)
                : translateReplacer(translate(i18nConstants.StreamVC_UserUnmuted), {xxx: fullName || ''});
        }

        if (isMe && isMute) {
            this.isMeMuted = true;
            const unMuteMsgIndex = this.chatMessages.findIndex(msg => msg.t === AdminMessageTypes.UNMUTE_ME);
            if (unMuteMsgIndex !== -1) {
                this.chatMessages.splice(unMuteMsgIndex, 1);
            }
            const myMessages = this.chatMessages.filter(msg => msg.sId === this.currentUser.id);
            myMessages.forEach(msg => {
                this.chatMessages.splice(this.chatMessages.indexOf(msg), 1);
            });
            // const msgIndex = this.chatMessages.findIndex(msg => msg.t === AdminMessageTypes.MUTE_ME);
            // if (msgIndex !== -1) {
            //     this.chatMessages.splice(msgIndex, 1);
            // }
        }

        if (isMe && !isMute) this.isMeMuted = false;
        const message = this.createAdminMessage(msgLocalize, msgType);
        this.chatMessages.push(message);
    }

    handleWheelResults(data: {userId: number; result: string; price: number}) {
        const user = this.partyUsers.find(user => user.id === data.userId);
        if (user) {
            const firstMsg = `%1s spin the wheel %2s`;
            const secondMsg = `Result: %1s`;

            const message: PartyMessageSocket = {
                roomId: this.roomId?.toString() || '0',
                rId: 0,
                sId: user.id,
                t: 6,
                me: '',
                m: '',
                gId: 0,
                messages: [firstMsg, secondMsg],
                data: data,
            };
            this.chatMessages.push(message);
        }
    }

    handleAdminNoticeMessage(msg: string, isError: boolean) {
        const message = this.createAdminMessage(msg, AdminMessageTypes.NOTICE);

        if (isError) {
            message.isWarning = true;
            message.warningContent = msg;
        }

        this.chatMessages.push(message);
    }

    onGiftRoomIdChange(roomId: number) {
        this.giftSelectedRoomId = roomId;
    }

    onGiftTabChange(tab: GiftTab) {
        this.giftSelectedTab = tab;
        this.changeCurrentGifts(tab);
        if (tab === 'lucky') {
            const el = this.giftTabs.nativeElement as HTMLDivElement;
            el.scrollTo({
                left: el.scrollWidth - el.clientWidth,
                behavior: 'smooth',
            });
        } else if (tab === 'popular') {
            const el = this.giftTabs.nativeElement as HTMLDivElement;
            el.scrollTo({
                left: 0,
                behavior: 'smooth',
            });
        }
    }

    changeCurrentGifts(tab: GiftTab) {
        switch (tab) {
            case 'lucky':
                this.currentGifts = this.gifts.filter(gift => gift.type == GiftType.LUCKY);
                break;
            case 'popular':
                this.currentGifts = this.gifts.filter(gift => gift.type == GiftType.POPULAR);
                break;
            case '3d':
                this.currentGifts = this.gifts.filter(gift => gift.type == GiftType.AR);
                break;
            case 'vip':
                this.currentGifts = this.gifts.filter(gift => gift.type == GiftType.VIP);
                break;
        }
    }

    emojiButtonClicked() {
        this.isEmojiPickerOpen = !this.isEmojiPickerOpen;
        if (this.chatInput && this.isEmojiPickerOpen) {
            this.chatInput.nativeElement.focus();
        }
    }

    addEmoji(event: any) {
        if (event.emoji) {
            this.partyMessageContent += event.emoji.native;
            this.chatInput && this.chatInput.nativeElement.focus();
        }
    }

    onChatInputFocus() {
        if (this.partyMessageContent.length > 0) this.isSendButtonOpen = true;
    }

    onChatInputFocusOut() {
        if (this.partyMessageContent.length === 0) this.isSendButtonOpen = false;
    }

    protected readonly open = open;
    protected readonly convertCoin = convertCoin;
    protected readonly Array = Array;
}
