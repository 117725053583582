import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-circular-progress-bar',
    standalone: true,
    imports: [],
    templateUrl: './circular-progress-bar.component.html',
    styleUrl: './circular-progress-bar.component.scss',
})
export class CircularProgressBarComponent {
    @Input() percent: number = 100;
    circumference: number;
    radius: number = 30;
    backgroundColor: string = 'rgba(255,255,255, 0.24)';
    foregroundColor: string = '#007bff'; // Default blue color, change as needed
    @Input() remainingTime!: number;
    constructor() {
        this.circumference = this.radius * 2 * Math.PI;
    }
}
