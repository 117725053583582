export enum SocketErrors {
    SUCCESS = 0,
    JOIN_PARTY_NEED_ROOM_ID = 1,
    PARTY_LIMITED_FINISHED = 2,
    WRONG_PARAMETERS = 3,
    NOT_ENOUGH_BALANCE = 4,
    PARTY_IS_FINISHED = 5,
    USER_BUSY = 6,
    NEED_PREMIUM = 7,
    DUPLICATE_ATTEMPT = 8,
    AGENTS_CANNOT_UNLOCK_MESSAGE = 9,
    USER_ID_NOT_FOUND = 10,
    ROOM_ID_NOT_FOUND = 11,
    USER_NOT_FOUND = 12,
    BLOCKED = 13,
    SENDER_NOT_FOUND = 14,
    RECEIVER_NOT_FOUND = 15,
    MAXIMUM_LENGTH = 16,
    COMMENT_NOT_ALLOWED = 17,
    LIMIT_EXCEEDED = 18,
    NOT_ELIGIBLE_FOR_MESSAGE = 19,
}
