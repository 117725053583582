<div
    (click)="onWheelOpen()"
    @fadeInOut
    class="bg-opacity-40 bg-[#3F424A] max-w-[266px] w-full h-auto p-2 flex flex-wrap justify-center items-center md:justify-between rounded-lg mt-[16px] select-none cursor-pointer lg:hover:bg-opacity-70 transition duration-300 ease-in-out lg:mt-2 lg:py-[10px] lg:px-4"
>
    <p class="text-xs font-semibold text-neutral-200">Spin</p>
    <div class="flex justify-center items-center">
        <img src="assets/images/shared/icons/party-wheel-icon.png" alt="wheel" class="size-6" />
    </div>
</div>

<div #wheelDivContainer *ngIf="showWheel" class="fixed w-full h-full max-h-full overflow-hidden z-[70]">
    <section #wheelModal @wheelScaleUpFlash class="wheel-modal">
        <div class="relative w-full max-w-xl mx-auto h-full max-h-full z-20">
            <div class="flex flex-col w-full h-full items-center select-none">
                <div #app class="w-full h-full flex items-center justify-center"></div>
                <div *ngIf="showWheelButtons" class="flex flex-col gap-3">
                    <div class="flex flex-row items-center justify-center gap-1">
                        <img src="assets/images/shared/icons/crystals.svg" alt="coin" class="size-7" />
                        <p class="font-medium text-white text-[17px]">{{ wheelData.price }}</p>
                    </div>
                    <button
                        (click)="onSpinButtonClick()"
                        class="w-[131px] h-10 bg-gradient-to-l from-[#26A7F0] to-[#BD00FF] flex items-center justify-center p-4 font-medium text-[14px] rounded-lg text-white"
                    >
                        SPIN
                    </button>
                </div>
            </div>
        </div>
    </section>

    <div class="wheel-modal-overlay backdrop-blur-lg" (click)="onWheelClose()"></div>
</div>
