import {CommonModule} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {ChatMessageType} from '@core/enums/chat-message-type.enum';
import {Room} from '@core/models/candidate/room';
import {ChatMessage} from '@core/models/chat/chat-message';
import {PartyMessageSocket} from '@core/models/chat/socket/party-message-socket';
import {PartyUserSocket} from '@core/models/chat/socket/party-user-socket';
import {GiftDetail} from '@core/models/gift/gift-detail';
import {animate, style, transition, trigger} from '@angular/animations';
import {AssetConstants} from '@core/constants/asset-constants';
import userPhotoConverter from '@core/utils/helpers/userPhotoConverter';
import {UserLevelBadgeComponent} from '@shared/components/user/user-level-badge/user-level-badge.component';
import {TranslocoPipe} from '@ngneat/transloco';
import {ChatSocketService} from '@core/services/chat/chat-socket.service';
import {take} from 'rxjs';

@Component({
    selector: 'app-live-stream-chat',
    standalone: true,
    imports: [CommonModule, UserLevelBadgeComponent, TranslocoPipe],
    templateUrl: './live-stream-chat.component.html',
    animations: [
        trigger('fadeToUp', [
            transition(':enter', [
                style({opacity: 0, transform: 'translateY(30px)'}),
                animate('300ms', style({opacity: 1, transform: 'translateY(0)'})),
            ]),
        ]),
    ],
    styleUrl: './live-stream-chat.component.scss',
})
export class LiveStreamChatComponent implements OnInit {
    @Input() message!: PartyMessageSocket;
    @Input() partyUsers!: PartyUserSocket[];
    @Input() room!: Room;
    @Input() gifts!: GiftDetail[];
    @Input() socketService!: ChatSocketService;

    chatMessage!: ChatMessage;
    messages: string[] = [];

    showMessage = false;
    chatMessageType = ChatMessageType;

    ngOnInit(): void {
        this.chatMessage = {
            username: '',
            avatar: this.room.thumb,
            senderId: this.message.sId,
            message: this.message.m,
            roomId: this.room.roomId,
            isPremium: false,
            isAgent: false,
            isStreamer: false,
            giftPic: '',
            giftFee: 0,
            mType: ChatMessageType.TEXT,
            level: 1,
            isWarning: this.message.isWarning!,
            warningContent: this.message.warningContent!,
        };
        if (this.message.isAdmin) {
            this.chatMessage.message = this.message.m;
        }
        if (this.message.t == 6) {
            let firstMsg = this.message.messages![0];
            const user = this.filterUserById(this.message.data.userId);
            firstMsg = firstMsg.replace('%1s', user.fullName);
            const textWithImg = `<div class="flex flex-row gap-1">${this.message.data.price} <img src="assets/images/shared/crystals.svg" alt="coin" class="w-4 h-4"></div>`;
            firstMsg = firstMsg.replace('%2s', textWithImg);

            let secondMsg = this.message.messages![1];
            secondMsg = secondMsg.replace('%1s', this.message.data.result);

            this.messages.push(firstMsg);
            this.messages.push(secondMsg);
        }
        if (this.message.gId) {
            this.prepareGiftContent();
        } else {
            this.prepareTextContent();
        }

        if (this.message.sId == this.room.id) {
            // sender is streamer
            this.chatMessage.username = this.room.fullName;
            this.chatMessage.isStreamer = true;
            this.chatMessage.avatar = this.room.thumb;
            this.chatMessage.level = this.room.userLevel;
            this.chatMessage.isPremium = this.room.isPremium;
            if (this.chatMessage.username !== '') {
                this.showMessage = true;
            }
        } else {
            const user = this.filterUserById(this.message.sId);
            if (user) {
                this.prepareUser(user);
            } else {
                this.socketService.getParticipantInfo(this.message.sId);
                this.socketService
                    .onGetParticipantInfo()
                    .pipe(take(1))
                    .subscribe(data => {
                        const user = JSON.parse(data);
                        if (user) {
                            this.prepareUser(user);
                        }
                    });
            }
        }
    }

    prepareAdminMessage() {
        this.chatMessage.message = this.message.m;
        this.showMessage = true;
    }

    prepareTextContent() {
        this.chatMessage.mType = ChatMessageType.TEXT;
    }

    prepareGiftContent() {
        const gift = this.getGiftDetail(this.message.gId);
        this.chatMessage.giftPic = gift.url;
        this.chatMessage.giftFee = gift.defaultFee;
        this.chatMessage.mType = ChatMessageType.GIFT;
    }

    getGiftDetail(giftId: number): GiftDetail {
        return this.gifts.filter(function (gift) {
            return gift.id == giftId;
        })[0];
    }

    filterUserById(userId: number): PartyUserSocket {
        return this.partyUsers.filter(function (user) {
            return user.id == userId;
        })[0];
    }

    prepareUser(user: PartyUserSocket) {
        if (user.photo === null || user.photo === '') user.photo = userPhotoConverter(user.id);
        this.chatMessage.avatar = user.photo;
        this.chatMessage.isPremium = user.isPremium;
        this.chatMessage.username = user.fullName;
        this.chatMessage.isAgent = user.isAgent;
        this.chatMessage.level = user.level;
        if (this.chatMessage.username !== '') {
            this.showMessage = true;
        } else if (this.message.isAdmin) {
            this.showMessage = true;
        }
    }

    protected readonly AssetConstants = AssetConstants;
}
