<div
    @fadeToUp
    *ngIf="showMessage && message.t !== 5 && message.t !== 6 && !message.isAdmin"
    class="flex flex-row my-1 break-words"
>
    <!-- Profile Picture -->
    <img *ngIf="!chatMessage.isStreamer" src="{{ chatMessage.avatar }}" alt="Profile" class="w-6 h-6 rounded-full" />

    <div *ngIf="chatMessage.isStreamer" class="relative w-[25px] h-[25px]">
        <img
            src="{{ chatMessage.avatar }}"
            alt="Profile"
            class="w-5 h-5 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full absolute"
        />
        <img src="assets/images/shared/user/streamer-badge.png" alt="Streamer Badge" class="w-full h-full absolute" />
    </div>

    <!-- Message & Username -->
    <div *ngIf="chatMessage.mType === chatMessageType.TEXT" class="flex flex-col ml-2 max-w-[190px] h-auto">
        <div class="flex flex-row items-center">
            <p class="text-sm font-bold text-white">{{ chatMessage.username }}</p>
            <app-user-level-badge
                *ngIf="chatMessage.isPremium"
                [level]="chatMessage.level"
                class="ml-1"
            ></app-user-level-badge>
        </div>

        <p class="text-xs font-medium text-white">{{ chatMessage.message }}</p>
    </div>

    <div
        *ngIf="chatMessage.mType === chatMessageType.GIFT"
        class="flex flex-row ml-2 max-w-[190px] h-auto items-center justify-center"
    >
        <p class="text-xs font-bold text-white">{{ chatMessage.username }} hediye gönderdi.</p>
        <img src="{{ chatMessage.giftPic }}" alt="rose-gift" class="size-6" />
        <p class="text-xs text-white">&nbsp;{{ chatMessage.giftFee }}</p>
        <img src="assets/images/shared/crystals.svg" class="size-6" alt="Crystals" />
    </div>
</div>

<!--<div @fadeToUp *ngIf="chatMessage.isWarning && showMessage && message.t !== 5" class="flex flex-row my-1 break-words rounded" style="background: linear-gradient(90deg, #FF2D6C 0%, rgba(0, 0, 0, 0) 96.66%);">

  <img *ngIf="chatMessage.isWarning" src="assets/images/shared/icons/admin-emoji.svg" alt="Profile"
    class="w-6 h-6 rounded-full" />


  <div *ngIf="chatMessage.isWarning" class="flex flex-col ml-2 max-w-[190px] h-auto" >
    <div class="flex flex-row items-center">
      <p class="text-sm font-bold text-white">Admin</p>
    </div>

    <p class="text-xs font-medium text-white">{{ chatMessage.message | transloco }}</p>
  </div>
</div>-->

<div @fadeToUp *ngIf="showMessage && message.t === 5" class="flex flex-row my-1 break-words">
    <p class="text-xs font-medium text-white">{{ chatMessage.username }} yayına katıldı</p>
</div>

<div
    @fadeToUp
    *ngIf="showMessage && message.isAdmin"
    class="flex flex-row w-full my-1 break-words gradient-bg rounded-md p-2 select-none"
>
    <img src="assets/images/shared/icons/admin-icon.png" alt="Admin" class="w-5 h-5 rounded-full" />
    <div class="flex flex-col justify-center ml-2 gap-1">
        <p class="text-xs font-bold text-[#FCF5FF]">Admin</p>
        <p class="text-xs font-medium text-white">{{ chatMessage.message | transloco }}</p>
    </div>
</div>

<div @fadeToUp *ngIf="showMessage && message.t === 6" class="flex flex-row my-1 break-words gap-1">
    <img *ngIf="!chatMessage.isStreamer" src="{{ chatMessage.avatar }}" alt="Profile" class="w-6 h-6 rounded-full" />
    <div class="flex flex-col gap-1">
        <p class="text-xs font-medium text-white" [innerHTML]="messages[0]"></p>
        <p class="text-xs font-medium text-white">{{ messages[1] }}</p>
    </div>
    <img src="assets/images/shared/icons/party-wheel-icon.png" alt="party-wheel" class="size-6" />
</div>
