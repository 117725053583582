<div class="relative w-8 h-8 top-1/2 left-1/2 select-none">
    <div class="relative w-7 h-7 inset-0">
        <img
            *ngIf="userPhoto"
            src="{{ userPhoto }}"
            alt="user"
            class="absolute w-6 h-6 rounded-full bottom-0.5 left-0.5 z-20"
        />
        <img
            *ngIf="!userPhoto"
            src="{{ pathPrefix }}-none.svg"
            alt="none"
            class="absolute bottom-2 left-[5.5px] w-4 h-4 z-20"
        />
        <img
            src="../../../../../assets/images/shared/user/user-borders/top-diamond.svg"
            alt="diamond"
            class="absolute w-[6px] h-[7.5px] z-30 left-1/2 transform translate-x-[-50%] -top-[2px]"
        />
        <img src="{{ pathPrefix }}-border.svg" alt="gold-border" class="absolute inset-0 w-full h-full z-10" />
        <img src="{{ pathPrefix }}-r-wing.svg" alt="r-wing" class="absolute w-[6px] height-[6px] right-0" />
        <img src="{{ pathPrefix }}-l-wing.svg" alt="l-wing" class="absolute w-[6px] height-[6px] left-0" />
        <div class="absolute w-4 h-4 -bottom-1 z-30">
            <img src="{{ pathPrefix }}-order.svg" alt="gold-order" class="absolute w-full h-full" />
            <span class="absolute text-[7px] font-semibold text-white left-[6.5px] bottom-[3px]">{{ position }}</span>
        </div>
    </div>
</div>
