import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {FollowButtonComponent} from '@core/components/ui-components/follow-button/follow-button.component';
import {NgForOf, NgIf} from '@angular/common';
import {StreamerCardComponent} from '@shared/components/streamer-card/streamer-card.component';
import {CandidateService} from '@core/services/candidate/candidate.service';
import {Router} from '@angular/router';
import {AuthService} from '@core/services';
import {Room} from '@core/models/candidate/room';
import {CandidateResponse} from '@core/models/candidate/candidate-response';
import {PiPService} from '@core/services/common/pip.service';
import {AgoraService} from '@core/services/agora/agora.service';

@Component({
    selector: 'app-stream-end',
    standalone: true,
    imports: [FollowButtonComponent, NgForOf, StreamerCardComponent, NgIf],
    templateUrl: './stream-end.component.html',
    styleUrl: './stream-end.component.scss',
})
export class StreamEndComponent implements OnInit, OnDestroy {
    candidateService = inject(CandidateService);
    authService = inject(AuthService);
    router = inject(Router);
    pipService = inject(PiPService);
    stream = inject(AgoraService);

    timeoutId!: NodeJS.Timeout;
    @Input() rooms!: Room[];
    @Input() room!: Room;
    @Input() channelName!: string;
    routeTimeout: number = 10;
    constructor() {}

    ngOnInit(): void {
        this.getCandidates();
        this.timeoutId = setTimeout(() => {
            this.goLive();
        }, this.routeTimeout * 1000);
    }

    ngOnDestroy() {
        clearTimeout(this.timeoutId);
    }

    getCandidates() {
        this.candidateService.getCandidates().subscribe((data: CandidateResponse) => {
            if (data.success) {
                this.rooms = data.candidates;
            } else {
                //error handler
            }
        });
    }

    goLive() {
        this.pipService.pipData$.next({
            room: this.room,
            service: this.stream,
            mute: true,
            isFinished: true,
            isPremium: false,
            channelName: this.channelName,
        });
        this.router.navigateByUrl('live');
    }

    goDetail(roomId: number, roomPhoto: string) {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate(['live/' + this.authService.cryptCommon(roomId.toString())], {
                state: {
                    roomPhoto: roomPhoto,
                },
            });
        });
    }
}
