<div
    (click)="onTypeChange()"
    (mouseenter)="onHover(false)"
    (mouseleave)="onHover(true)"
    class="flex flex-row items-center justify-center gap-1 select-none cursor-pointer relative w-full transition ease-in-out duration-300"
>
    <div #iconContainer [ngClass]="currentTab === type ? 'text-white' : 'text-[#7E8594]'"></div>
    <p
        class="font-medium text-[14px] text-center capitalize"
        [ngClass]="currentTab === type ? 'text-white' : 'text-[#7E8594]'"
    >
        {{ type === '3d' || type === 'vip' ? type.toUpperCase() : type }}
    </p>

    <div
        *ngIf="currentTab === type || isHovered"
        class="transition ease-in-out duration-300 absolute -bottom-2 max-w-[80%] w-full transform origin-center"
    >
        <div
            [ngClass]="isHovered && currentTab !== type ? 'border-[#7E8594]' : 'border-primary-500'"
            class="border-[2px]"
        ></div>
    </div>
</div>
