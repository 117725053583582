import {Component, EventEmitter, Input, Output} from '@angular/core';

export interface MarqueeItem {
    senderId: number;
    senderName: string;
    receiverId: number;
    receiverName: string;
    giftId: number;
    giftName: string;
    giftPrice: number;
    roomId: number;
}

export interface MarqueeList {
    item: MarqueeItem;
    index: number;
}

@Component({
    selector: 'app-marquee',
    standalone: true,
    imports: [],
    templateUrl: './marquee.component.html',
    styleUrl: './marquee.component.scss',
})
export class MarqueeComponent {
    @Input() data!: MarqueeItem;
    @Output() marqueeFinish: EventEmitter<void> = new EventEmitter();

    onAnimationEnd() {
        this.marqueeFinish.emit();
    }
}
