import {Component, Input} from '@angular/core';
import {GoalDetail} from '@core/models/stream-feature/goal-detail';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-party-feature-goal',
    standalone: true,
    imports: [],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: 0, transform: 'translateX(-30px)'}),
                animate('300ms', style({opacity: 1, transform: 'translateX(0)'})),
            ]),
        ]),
    ],
    templateUrl: './goal.component.html',
    styleUrl: './goal.component.scss',
})
export class GoalComponent {
    @Input() featureData!: GoalDetail;
}
