import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import * as PIXI from 'pixi.js';
import * as TWEEN from '@tweenjs/tween.js';
import {TranslocoDirective, TranslocoPipe} from '@ngneat/transloco';
import {ModalService} from '@core/services/modal/modal.service';
import {PartyWheelDetail} from '@core/models/stream-feature/wheel-detail';
import {AuthService} from '@core/services';
import {PurchaseComponent} from '@pages/purchase/purchase.component';
import {ChatSocketService} from '@core/services/chat/chat-socket.service';
import {NgIf} from '@angular/common';
import {animate, style, transition, trigger} from '@angular/animations';

export interface SpinFinishEvent {
    userId: number;
    result: string;
    price: number;
}

@Component({
    selector: 'app-party-feature-wheel',
    standalone: true,
    imports: [TranslocoPipe, TranslocoDirective, NgIf],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: 0, transform: 'translateX(-30px)'}),
                animate('300ms', style({opacity: 1, transform: 'translateX(0)'})),
            ]),
        ]),
        trigger('wheelScaleUpFlash', [
            transition(':enter', [
                style({
                    transform: 'scale(0) translate(-50%, -50%)',
                    transformOrigin: 'left top',
                    opacity: 0,
                    offset: 0,
                }),
                animate(
                    '300ms',
                    style({
                        transform: 'scale(1) translate(-50%, -50%)',
                        transformOrigin: 'left top',
                        opacity: 1,
                        offset: 1,
                    }),
                ),
            ]),
            transition(':leave', [
                style({
                    transform: 'scale(1.5) translate(-50%, -50%)',
                    transformOrigin: 'left top',
                    opacity: 1,
                    offset: 0,
                }),
                animate(
                    '300ms',
                    style({
                        transform: 'scale(1) translate(-50%, -50%)',
                        transformOrigin: 'left top',
                        opacity: 0,
                        offset: 1,
                    }),
                ),
            ]),
        ]),
    ],
    templateUrl: './wheel.component.html',
    styleUrl: './wheel.component.scss',
})
export class WheelComponent implements OnInit, OnChanges, OnDestroy {
    pixiApp!: PIXI.Application;

    showWheel = false;
    showWheelButtons = true;

    wheelArrow!: PIXI.Sprite;
    sliceContainer!: PIXI.Container;

    wheelContainer!: PIXI.Container;

    @Input() wheelData!: PartyWheelDetail;
    @Output() spinFinish = new EventEmitter<SpinFinishEvent>();
    idleSpinTime = 40;
    spinTime = 5;
    spinSpeed = 6;

    oldActions!: string[];
    idleWheelAnimation!: TWEEN.Tween<PIXI.Container>;
    isSpinning = false;
    degreeOffset = 270;

    sliceCount = 8;
    sliceGraphics: PIXI.Graphics[] = [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sprites!: Record<string, any>;
    innerRadius = 150;

    authService = inject(AuthService);

    userBalance: number = 0;

    wheelQueue: PartyWheelDetail[] = [];

    @ViewChild('app') appContainer!: ElementRef;

    @ViewChild('wheelDivContainer', {static: false}) wheelDivContainer!: ElementRef;

    @ViewChild('wheelModal', {static: false}) wheelModal!: ElementRef;

    constructor(
        private modalService: ModalService,
        private socketService: ChatSocketService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.userBalance = this.authService.userDetail.balance;
        this.oldActions = this.wheelData.actions;
    }
    ngOnDestroy() {
        this.showWheel = false;
        this.showWheelButtons = true;
    }

    async ngOnChanges(changes: SimpleChanges) {
        if ('wheelData' in changes) {
            this.sliceCount = this.wheelData.actions.length;
            if (!this.wheelData.result && this.showWheel) {
                const oldActions = JSON.stringify(this.oldActions);
                const actions = JSON.stringify(this.wheelData.actions);
                if (this.oldActions && oldActions !== actions) {
                    this.createSlices();
                    this.oldActions = this.wheelData.actions;
                }
            }
            if (this.wheelData.result) {
                if (this.isSpinning) {
                    this.wheelQueue.push(this.wheelData);
                    return;
                }
                await this.openWheelAndSpin(this.wheelData.result);
            }
        }
    }

    destroyApp() {
        this.pixiApp?.destroy(true);
    }

    async initializeApp() {
        this.pixiApp = new PIXI.Application();
        await this.pixiApp.init({
            backgroundAlpha: 0,
            antialias: true,
            width: 320,
            height: 320,
            resolution: 2,
        });

        this.appContainer.nativeElement.appendChild(this.pixiApp.canvas);
        this.pixiApp.ticker.add(() => {
            TWEEN.default.update();
        });
        this.pixiApp.stage.scale.set(0.5);
        await this.loadAssets();
    }

    prepareScreen() {
        this.wheelContainer = new PIXI.Container();
        this.wheelContainer.position.set(this.pixiApp.screen.width, this.pixiApp.screen.height);

        const wheelFrame = this.createWheelFrame();
        const wheelInnerBg = new PIXI.Graphics();
        wheelInnerBg.circle(0, 0, this.innerRadius);
        wheelInnerBg.fill({color: 0xffffff});
        wheelInnerBg.zIndex = 2;
        wheelInnerBg.pivot.set(0.5);
        this.wheelContainer.addChild(wheelFrame);
        this.wheelContainer.addChild(wheelInnerBg);
        const wheelArrowContainer = this.createWheelArrow();
        this.wheelContainer.addChild(wheelArrowContainer);
        const wheelSlices = this.createWheelSlices(this.sliceCount, this.innerRadius);
        this.wheelContainer.addChild(wheelSlices);
        this.pixiApp.stage.addChild(this.wheelContainer);

        this.wheelIdleAnimation();
    }

    createWheelFrame() {
        const wheelFrameContainer = new PIXI.Container();
        const wheelFrame = new PIXI.Graphics();
        const wheelFrameRadius = 160;
        wheelFrame.circle(0, 0, wheelFrameRadius);
        wheelFrame.pivot.set(0.5);
        wheelFrame.fill({color: 0xe6245e});
        wheelFrame.zIndex = 1;
        wheelFrameContainer.addChild(wheelFrame);

        const dotContainer = new PIXI.Container();
        const dotCount = 38;
        const dotAngle = 360 / dotCount;
        for (let i = 0; i < dotCount; i++) {
            const dot = new PIXI.Graphics();
            const isEven = i % 2 === 0;

            const angle = dotAngle * i;
            const radian = (angle * Math.PI) / 180;
            const dotRadius = 3.5;
            const offset = dotRadius + dotRadius / 2;
            const dotX = (wheelFrameRadius - offset) * Math.sin(radian);
            const dotY = -((wheelFrameRadius - offset) * Math.cos(radian));
            dot.circle(0, 0, dotRadius);
            dot.fill({color: isEven ? 0xdcb5ff : 0xf6e8b5});
            dot.position.set(dotX, dotY);
            dot.pivot.set(0.5);
            dot.zIndex = 2;
            dotContainer.addChild(dot);
        }
        dotContainer.position.set(0, 0);
        dotContainer.zIndex = 15;
        wheelFrameContainer.addChild(dotContainer);

        return wheelFrameContainer;
    }

    createWheelArrow() {
        const wheelArrowContainer = new PIXI.Container();
        this.wheelArrow = new PIXI.Sprite(this.sprites['wheel-arrow']);
        this.wheelArrow.anchor.set(0.5);
        this.wheelArrow.scale.set(1.5);

        const innerCircle = new PIXI.Graphics();
        innerCircle.circle(0, 0, 30);
        innerCircle.fill({color: 0xffffff});
        innerCircle.pivot.set(0.5);
        innerCircle.position.set(0, 0);

        const outerCircle = new PIXI.Graphics();
        outerCircle.circle(0, 0, 35);
        outerCircle.fill({color: 0xe6245e});
        outerCircle.pivot.set(0.5);
        outerCircle.position.set(0, 0);

        innerCircle.zIndex = 3;
        outerCircle.zIndex = 2;
        this.wheelArrow.position.set(0, outerCircle.bounds.minY + 5);
        this.wheelArrow.zIndex = 1;
        wheelArrowContainer.addChild(this.wheelArrow);
        wheelArrowContainer.addChild(innerCircle);
        wheelArrowContainer.addChild(outerCircle);
        wheelArrowContainer.zIndex = 5;

        return wheelArrowContainer;
    }

    createWheelSlices(sliceCount: number, radius: number) {
        this.sliceContainer = new PIXI.Container();
        // const sliceTexture = this.sprites['wheel-slice-normal'];
        const radianPerSector = (Math.PI * 2) / sliceCount;
        this.sliceContainer.rotation = (this.degreeOffset * Math.PI) / 180;

        for (let i = 0; i < sliceCount; i++) {
            const perSliceContainer = new PIXI.Container();

            const slice = new PIXI.Graphics();
            const startingAngle = i * radianPerSector - radianPerSector / 2;
            const endingAngle = startingAngle + radianPerSector;
            // Draw the triangle
            // slice.moveTo(0, 0);
            // slice.lineTo(radius * Math.cos(startingAngle), radius * Math.sin(startingAngle));
            // slice.lineTo(radius * Math.cos(endingAngle), radius * Math.sin(endingAngle));
            // slice.lineTo(0, 0);

            // Fill the triangle with a color
            slice.moveTo(0, 0);
            slice.arc(0, 0, radius, startingAngle, endingAngle);
            slice.lineTo(0, 0);
            slice.stroke({color: 0xc1c6d1, width: 2});
            slice.fill({color: 0xffffff});
            slice.position.set(0, 0);
            slice.pivot.set(0.5);

            // Add text to the slice
            const text = new PIXI.Text({
                text: `${this.wheelData.actions[i]}`,
                style: {
                    fontFamily: 'Arial',
                    fontSize: 12,
                    fill: 0x000000,
                    align: 'center',
                },
            });

            // Calculate the middle angle of the slice for positioning the text
            const middleAngle = startingAngle + radianPerSector / 2;
            const textRadius = radius * 0.75; // Adjust this value to control the distance from the center
            text.position.set(textRadius * Math.cos(middleAngle), textRadius * Math.sin(middleAngle));
            text.anchor.set(0.5); // Center the text

            // Rotate the text to match the slice's angle
            text.rotation = middleAngle + Math.PI / 2; // Adjust the rotation

            // const angle = sliceAngle * i;
            // const radian = (angle * Math.PI) / 180;
            // const sliceX = (radius / 2) * Math.sin(radian);
            // const sliceY = -((radius / 2) * Math.cos(radian));
            // perSliceContainer.position.set(sliceX, sliceY);
            // perSliceContainer.angle = sliceAngle * i;
            perSliceContainer.zIndex = 2;

            // const textContainer = this.createTextContainer('TEST TEST');
            slice.zIndex = 1;
            text.zIndex = 3;

            // textContainer.position.set(sliceX, sliceY);

            // textContainer.zIndex = 15;
            perSliceContainer.addChild(text);
            perSliceContainer.addChild(slice);
            this.sliceGraphics.push(slice);
            this.sliceContainer.addChild(perSliceContainer);
        }
        this.sliceContainer.position.set(0, 0);
        this.sliceContainer.zIndex = 4;
        return this.sliceContainer;
    }

    clearSlices() {
        this.pixiApp.stage.removeChild(this.sliceContainer);
        this.sliceContainer.destroy();
        this.sliceGraphics = [];
    }

    createSlices() {
        // this.sliceCount += 1;
        this.clearSlices();
        const wheelSlices = this.createWheelSlices(this.sliceCount, this.innerRadius);
        this.wheelContainer.addChild(wheelSlices);
        this.wheelIdleAnimation();
    }

    wheelIdleAnimation() {
        const rotation = this.sliceContainer.rotation + Math.PI * 2;
        this.idleWheelAnimation = new TWEEN.default.Tween(this.sliceContainer)
            .to({rotation}, this.idleSpinTime * 1000)
            .easing(TWEEN.default.Easing.Linear.None)
            .repeat(Infinity)
            .start();
    }

    onSpinButtonClick() {
        if (this.wheelData.price > this.authService.userDetail.balance) {
            this.showWheel = false;
            this.modalService.open(PurchaseComponent);
            return;
        }
        this.socketService.spinPartyWheel(this.wheelData.id);
        this.cdr.detectChanges();
    }

    spinWheel(sliceIndex: number) {
        if (this.isSpinning) return;
        if (this.idleWheelAnimation) this.idleWheelAnimation.stop();
        const realIndex = this.sliceCount - sliceIndex;
        const rotationDegree = realIndex * (360 / this.sliceCount) + 360 * this.spinSpeed + this.degreeOffset;
        // const rotationDegree = 270 + 3 * (360 / this.sliceCount);
        const rotationDegreeToRad = (rotationDegree * Math.PI) / 180;

        const spin = new TWEEN.default.Tween(this.sliceContainer)
            .to({rotation: rotationDegreeToRad}, this.spinTime * 1000)
            .easing(TWEEN.default.Easing.Quadratic.InOut)
            .start();
        spin.onStart(() => {
            this.isSpinning = true;
        });

        spin.onComplete(() => {
            this.sliceContainer.rotation = ((realIndex * (360 / this.sliceCount) + this.degreeOffset) * Math.PI) / 180;
            this.animateSlice(sliceIndex);
            // this.sliceContainer.angle = this.degreeOffset;
            // this.wheelIdleAnimation();
        });
    }

    animateSlice(sliceIndex: number) {
        const slice = this.sliceGraphics[sliceIndex];
        slice.stroke({color: 0xe6245e, width: 0});
        slice.fill({color: 0xffdde7});

        slice.zIndex = 3;
        const sliceAnimation = new TWEEN.default.Tween(slice)
            .to({alpha: 0.5}, 300)
            .easing(TWEEN.default.Easing.Quadratic.InOut)
            .start()
            .repeat(8);

        sliceAnimation.onComplete(() => {
            slice.alpha = 1;
            slice.stroke({color: 0xc1c6d1, width: 2});
            slice.fill({color: 0xffffff});
            this.isSpinning = false;
            this.handleSpinFinish({
                userId: this.wheelData.userId!,
                result: this.wheelData.actions[sliceIndex],
                price: this.wheelData.price,
            });
            this.onWheelClose();
        });
    }

    async loadAssets() {
        if (!PIXI.Assets.get('wheel-slice-normal')) {
            PIXI.Assets.add({
                alias: 'wheel-slice-normal',
                src: '../assets/images/shared/party-wheel/party-wheel-slice.png',
            });
        }

        if (!PIXI.Assets.get('wheel-slice-active')) {
            PIXI.Assets.add({
                alias: 'wheel-slice-active',
                src: '../assets/images/shared/party-wheel/party-wheel-active-slice.png',
            });
        }

        if (!PIXI.Assets.get('wheel-arrow')) {
            PIXI.Assets.add({alias: 'wheel-arrow', src: '../assets/images/shared/party-wheel/party-wheel-arrow.png'});
        }

        this.sprites = await PIXI.Assets.load(['wheel-slice-normal', 'wheel-slice-active', 'wheel-arrow']);
    }

    handleSpinFinish(data: SpinFinishEvent) {
        this.spinFinish.emit(data);
    }

    onWheelClose() {
        if (this.isSpinning) return;
        this.showWheel = false;
        this.showWheelButtons = true;
        this.clearSlices();
        this.destroyApp();
        setTimeout(() => {
            void this.handleQueue();
        }, 500);
    }

    async openWheelAndSpin(index: number) {
        this.showWheelButtons = false;
        await this.onWheelOpen();
        this.spinWheel(index);
    }

    async onWheelOpen() {
        if (this.showWheel) return;
        this.showWheel = true;
        this.cdr.detectChanges();
        document.body.appendChild(this.wheelDivContainer.nativeElement);
        await this.initializeApp();
        this.prepareScreen();
    }

    async handleQueue() {
        if (this.wheelQueue.length > 0) {
            const nextWheel = this.wheelQueue.shift();
            await this.openWheelAndSpin(nextWheel?.result || 0);
        }
    }

    onQueueFinished() {
        //output event when queue has been finished
    }
}
