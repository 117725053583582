<div class="flex flex-row justify-between py-2">
    <div class="flex flex-row justify-center">
        <img src="assets/images/shared/testfoto.jpeg" alt="profile photo" class="size-8 rounded-full" />
        <div class="flex flex-col pl-1">
            <h1 class="text-white font-semibold text-sm">Hanna</h1>
            <div class="flex flex-row">
                <img src="assets/images/shared/crystals.svg" alt="crystals" class="size-4" />
                <p class="text-white font-medium text-xs">1234</p>
            </div>
        </div>
    </div>
    <app-follow-button></app-follow-button>
</div>
