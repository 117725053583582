<div class="h-full w-full max-w-full">
    <div *ngIf="screenWidth > 1024" class="absolute w-full h-full top-0 left-0 bottom-0 right-0">
        <img *ngIf="room" src="{{ room.photo }}" alt="Streamers" class="h-full w-full object-cover" />
    </div>
    <div class="relative top-0 left-0 right-0 bottom-0 w-full h-full backdrop-blur-lg">
        <div class="absolute top-6 left-6 z-50">
            <div
                (click)="routeToHome()"
                class="rounded-full w-8 h-8 bg-[#3F424A] lg:w-12 lg:h-12 flex items-center justify-center cursor-pointer hover:bg-neutral-500 transition-colors duration-300 ease-in-out select-none"
            >
                <img src="assets/images/shared/icons/chevron-left.svg" alt="Back" class="w-4 h-4 lg:w-6 lg:h-6" />
            </div>
        </div>
        <div class="absolute top-0 bottom-0 left-0 right-0 max-w-full bg-black bg-opacity-20 z-20"></div>
        <div
            class="absolute top-0 bottom-0 left-0 right-0 max-w-full lg:top-1/2 lg:left-1/2 lg:transform lg:translate-x-[-50%] lg:translate-y-[-50%] w-full h-full lg:max-w-3xl z-30"
        >
            <div class="absolute top-0 bottom-0 left-0 right-0 max-w-full bg-black bg-opacity-20 z-20"></div>
            <div class="absolute top-0 bottom-0 right-0 left-0 w-full h-full">
                <img src="{{ room.photo !== undefined ? room.photo : '' }}" class="w-full h-full" />
            </div>

            <div
                class="absolute flex flex-col items-center justify-center w-full h-full backdrop-blur-lg z-50 select-none"
            >
                <div class="flex flex-col w-full h-full items-center justify-between pb-10">
                    <div class="flex flex-col w-full h-full items-center justify-center">
                        <img src="{{ room.photo }}" class="w-20 h-20 rounded-full mb-4" />
                        <h1 class="text-lg font-semibold text-white mb-8">{{ room.fullName }}</h1>

                        <div
                            class="flex flex-col justify-center items-center rounded-lg bg-[#3F424A] bg-opacity-40 p-4 mb-8"
                        >
                            <img src="{{ gift?.url }}" class="w-12 h-12" />
                            <div class="flex justify-center">
                                <img src="assets/images/shared/crystals.svg" class="w-6 h-6" />
                                <p class="text-md font-medium text-white">{{ gift?.defaultFee }}</p>
                            </div>
                        </div>
                        <h1 class="text-lg font-semibold text-white">Moved to Premium</h1>
                    </div>
                    <button
                        (click)="giftToJoin()"
                        class="w-[172px] h-[40px] bg-primary-500 hover:bg-primary-600 select-none transition-all rounded-lg flex items-center justify-center text-white px-6 py-3 text-sm font-medium cursor-pointer justify-self-end"
                    >
                        <p>Gift to Join</p>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
