import {CircularProgressBarComponent} from '@core/components/ui-components/circular-progress-bar/circular-progress-bar.component';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, interval, Subscription} from 'rxjs';
import {takeWhile} from 'rxjs/operators';
@Component({
    selector: 'app-host-disconnect',
    standalone: true,
    imports: [CircularProgressBarComponent],
    templateUrl: './host-disconnect.component.html',
    styleUrl: './host-disconnect.component.scss',
})
export class HostDisconnectComponent implements OnInit, OnDestroy {
    progress: number = 100;
    timer!: Observable<number>;
    timerSubscription!: Subscription;
    remainingSeconds: number = 90;
    constructor() {}

    ngOnInit(): void {
        this.progress = 100;

        this.timer = interval(1000).pipe(takeWhile(count => count <= 90));

        this.timerSubscription = this.timer.subscribe(() => {
            if (this.remainingSeconds > 0) {
                this.remainingSeconds--;
                this.progress -= 100 / 90;

                this.progress = Math.max(0, this.progress); // Ensure progress doesn't go below 0
                this.progress = Math.round(this.progress * 100) / 100;
            }
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from the timer to avoid memory leaks
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
    }
}
