import {HttpClient} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    http = inject(HttpClient);

    private userBaseUrl: string;

    constructor() {
        this.userBaseUrl = environment.apiUrl + '/user';
    }

    getBlockedWords(fileUrl: string): Observable<unknown> {
        return this.http.get(fileUrl);
    }

    async getBlockedWordsFromUrl(fileUrl: string) {
      const response = await fetch(fileUrl);
      let returnData;
      if (response.ok) {
        returnData = await response.text();
        return returnData;

      }
    }
}
