import {Component, Input} from '@angular/core';
import {StickerDetail} from '@core/models/stream-feature/sticker-detail';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-party-feature-sticker',
    standalone: true,
    imports: [],
    templateUrl: './sticker.component.html',
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity: 0, transform: 'translateX(-30px)'}),
                animate('300ms', style({opacity: 1, transform: 'translateX(0)'})),
            ]),
        ]),
    ],
    styleUrl: './sticker.component.scss',
})
export class StickerComponent {
    @Input() featureData!: StickerDetail;
}
