import {NgIf} from '@angular/common';
import {Component, Input, OnInit, inject} from '@angular/core';
import {PartyUserSocket} from '@core/models/chat/socket/party-user-socket';
import {CommonResponse} from '@core/models/common/common-response';
import {UserService} from '@core/services/user/user.service';
import userPhotoConverter from '@core/utils/helpers/userPhotoConverter';
import {UserLevelBadgeComponent} from '@shared/components/user/user-level-badge/user-level-badge.component';
import {UserLevelProfilePictureComponent} from '@shared/components/user/user-level-profile-picture/user-level-profile-picture.component';

@Component({
    selector: 'app-live-stream-users',
    standalone: true,
    imports: [NgIf, UserLevelBadgeComponent, UserLevelProfilePictureComponent],
    templateUrl: './live-stream-users.component.html',
    styleUrl: './live-stream-users.component.scss',
})
export class LiveStreamUsersComponent implements OnInit {
    @Input() user!: PartyUserSocket;
    @Input() index!: number;
    userService = inject(UserService);
    isPhotoAvailable: boolean = false;
    showFollowBtn: boolean = true;

    order!: string;

    ngOnInit(): void {
        if (this.user.photo === null || this.user.photo === '') {
            this.user.photo = userPhotoConverter(this.user.id);
        }

        if (this.user.spending != 0) {
            switch (this.index) {
                case 0:
                    this.order = 'first';
                    break;
                case 1:
                    this.order = 'second';
                    break;
                case 2:
                    this.order = 'third';
                    break;
                default:
                    this.order = `${this.index + 1}th`;
                    break;
            }
        }
    }

    followUser() {
        this.userService
            .followUser(this.user.id, {fullName: this.user.fullName, photo: this.user.photo})
            .subscribe((response: CommonResponse) => {
                if (response.success) {
                    this.showFollowBtn = false;
                }
            });
    }
}
